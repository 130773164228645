import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { useFetchManagersTable } from '../../services/hooks/manager/useFetchManagersTable';
import { TemplatesTableManagers } from '../../components/templatesTableManagers';
import { useState, useEffect } from 'react';
import { Loader } from 'components';
import { useSelector } from 'react-redux';
import { selectAcademy } from 'store';
interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}
export const Manager = () => {
    const { trans } = useLocales();
    const [columns, setColumns] = useState<Column[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const { academy } = useSelector(selectAcademy);
    const { data, isLoading, error } = useFetchManagersTable(academy.id, page, limit);

    useEffect(() => {
        setColumns([
            {
                key: 'manager',
                label: trans('manager.table.manager'),
                width: '20%',
                sortable: false,
            },
            {
                key: 'experiences',
                label: trans('manager.table.experiences'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'position',
                label: trans('manager.table.position'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'contact',
                label: trans('manager.table.contact'),
                width: '15%',
                sortable: false,
            },
            {
                key: 'contract',
                label: trans('manager.table.contract'),
                width: '15%',
                sortable: true,
            },
        ]);
    }, [trans]);

    if (error) return <div>Error: {error.message}</div>;
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <TemplatesTableManagers columns={columns} data={data || []} />
            )}
        </>
    );
};
