import React, { useRef, useState } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import { useLocales } from 'hooks/locales';
import * as Theme from './theme';

interface FilePickerControllerProps<T extends FieldValues> extends UseControllerProps<T> {
    label?: string;
    accept?: string;
}

export const FilePickerController = <T extends FieldValues>({
    label,
    control,
    name,
    accept = 'image/*',
}: FilePickerControllerProps<T>) => {
    const { field } = useController<T>({ control, name });
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const { trans } = useLocales();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setFileName(file.name);
            field.onChange(file);
        }
    };

    const handleClick = () => fileInputRef.current?.click();

    return (
        <Theme.FieldWrapper>
            {label && <Theme.Label>{label}</Theme.Label>}
            <Theme.FilePickerContainer onClick={handleClick}>
                <Theme.FileInput
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept={accept}
                />
                <Theme.DisplayBox>
                    {fileName || trans('file.chooseFile', 'Choose file')}
                </Theme.DisplayBox>
            </Theme.FilePickerContainer>
        </Theme.FieldWrapper>
    );
};
