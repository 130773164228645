/* eslint-disable react/no-unknown-property */
export const EmptyDOCS = () => {
    return (
        <svg
            width="200"
            height="201"
            viewBox="0 0 200 201"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5226_65651)">
                <mask
                    id="mask0_5226_65651"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="-11"
                    y="-12"
                    width="223"
                    height="224"
                >
                    <rect
                        x="-11"
                        y="-11.0016"
                        width="222.59"
                        height="222.59"
                        fill="url(#paint0_linear_5226_65651)"
                    />
                </mask>
                <g mask="url(#mask0_5226_65651)">
                    <circle
                        opacity="0.9"
                        cx="100.295"
                        cy="100.294"
                        r="24.9436"
                        stroke="#C0D330"
                        strokeWidth="0.375"
                    />
                    <circle
                        opacity="0.8"
                        cx="100.295"
                        cy="100.294"
                        r="35.7141"
                        stroke="#C0D330"
                        strokeWidth="0.375"
                    />
                    <circle
                        opacity="0.7"
                        cx="100.295"
                        cy="100.294"
                        r="46.4846"
                        stroke="#C0D330"
                        strokeWidth="0.375"
                    />
                    <circle
                        opacity="0.6"
                        cx="100.295"
                        cy="100.294"
                        r="57.2551"
                        stroke="#C0D330"
                        strokeWidth="0.375"
                    />
                    <circle
                        opacity="0.5"
                        cx="100.295"
                        cy="100.294"
                        r="68.0256"
                        stroke="#C0D330"
                        strokeWidth="0.375"
                    />
                    <circle
                        opacity="0.4"
                        cx="100.295"
                        cy="100.294"
                        r="78.7961"
                        stroke="#C0D330"
                        strokeWidth="0.375"
                    />
                    <circle
                        opacity="0.3"
                        cx="100.295"
                        cy="100.294"
                        r="89.5666"
                        stroke="#C0D330"
                        strokeWidth="0.375"
                    />
                    <circle
                        opacity="0.2"
                        cx="100.295"
                        cy="100.294"
                        r="100.337"
                        stroke="#C0D330"
                        strokeWidth="0.375"
                    />
                    <circle
                        opacity="0.1"
                        cx="100.295"
                        cy="100.294"
                        r="111.108"
                        stroke="#C0D330"
                        strokeWidth="0.375"
                    />
                    <circle
                        cx="100.295"
                        cy="100.294"
                        r="14.1732"
                        stroke="#C0D330"
                        strokeWidth="0.375"
                    />
                </g>
                <path
                    d="M132.419 79.6478C119.172 79.6478 105.688 79.1097 93.0739 75.5963C80.6975 72.1619 69.3341 65.4989 59.2051 57.7914C52.5738 52.7744 46.5439 48.7861 37.9343 49.3875C29.5043 49.849 21.4468 53.0121 14.9542 58.4087C4.00222 67.9046 5.64817 85.6777 10.0321 98.1649C16.616 116.967 36.6523 129.992 53.6817 138.507C73.354 148.335 94.973 154.032 116.64 157.324C135.631 160.205 160.036 162.31 176.495 149.902C191.61 138.507 195.756 112.488 192.053 94.9204C191.155 89.726 188.392 85.038 184.282 81.7369C173.662 73.982 157.82 79.1572 145.887 79.4263C141.456 79.5212 136.945 79.632 132.419 79.6478Z"
                    fill="#EBF3CB"
                />
                <path
                    d="M100.006 188.107C132.093 188.107 158.105 186.484 158.105 184.483C158.105 182.481 132.093 180.858 100.006 180.858C67.9186 180.858 41.9067 182.481 41.9067 184.483C41.9067 186.484 67.9186 188.107 100.006 188.107Z"
                    fill="#EBF3CB"
                />
                <path
                    d="M159.371 60.3079V67.1291"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M155.969 63.7262H162.774"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.0721 117.631V124.437"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M41.6537 121.034H48.4749"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M35.5286 31.9467V38.7521"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M32.1102 35.3494H38.9315"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M109.692 16.1518C110.548 16.1518 111.243 15.4574 111.243 14.6008C111.243 13.7442 110.548 13.0498 109.692 13.0498C108.835 13.0498 108.141 13.7442 108.141 14.6008C108.141 15.4574 108.835 16.1518 109.692 16.1518Z"
                    fill="#DEEAA7"
                />
                <path
                    d="M105.988 167.453C106.845 167.453 107.539 166.759 107.539 165.902C107.539 165.046 106.845 164.351 105.988 164.351C105.132 164.351 104.437 165.046 104.437 165.902C104.437 166.759 105.132 167.453 105.988 167.453Z"
                    fill="#DEEAA7"
                />
                <path
                    d="M139.296 23.5532L49.0324 32.9288C46.9719 33.1428 45.4751 34.9866 45.6891 37.0471L58.034 155.898C58.248 157.958 60.0919 159.455 62.1524 159.241L152.416 149.866C154.476 149.652 155.973 147.808 155.759 145.747L143.414 26.8965C143.2 24.836 141.357 23.3391 139.296 23.5532Z"
                    fill="#E5EFB9"
                />
                <path
                    d="M135.412 29.0391L53.9479 37.5007C51.8874 37.7147 50.3905 39.5585 50.6046 41.619L61.769 149.104C61.983 151.165 63.8268 152.662 65.8873 152.448L147.351 143.986C149.412 143.772 150.909 141.928 150.695 139.868L139.53 32.3824C139.316 30.3219 137.472 28.8251 135.412 29.0391Z"
                    fill="white"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M121.356 34.3048L120.09 22.1184C120.026 21.6126 119.767 21.1518 119.368 20.8352C118.968 20.5185 118.46 20.3712 117.953 20.4249L102.886 22.0076C102.764 20.7917 102.403 19.6119 101.824 18.5356C101.246 17.4592 100.461 16.5073 99.5144 15.7343C98.568 14.9613 97.4786 14.3823 96.3083 14.0303C95.1381 13.6783 93.91 13.5602 92.6942 13.6828C91.4783 13.8055 90.2985 14.1664 89.2222 14.7449C88.1458 15.3235 87.1939 16.1084 86.4209 17.0549C84.8597 18.9663 84.1218 21.4196 84.3694 23.8751L69.2551 25.4578C69.0004 25.4835 68.7533 25.5592 68.5278 25.6805C68.3024 25.8019 68.1032 25.9665 67.9414 26.1649C67.7797 26.3634 67.6588 26.5918 67.5854 26.8371C67.5121 27.0824 67.4879 27.3397 67.5142 27.5943L68.7803 39.7808C68.806 40.0355 68.8817 40.2826 69.003 40.508C69.1244 40.7334 69.289 40.9327 69.4875 41.0944C69.6859 41.2561 69.9143 41.3771 70.1596 41.4504C70.4049 41.5237 70.6622 41.5479 70.9169 41.5217L119.631 36.4572C119.887 36.4317 120.134 36.3554 120.36 36.2328C120.586 36.1103 120.785 35.9439 120.946 35.7434C121.106 35.543 121.225 35.3125 121.296 35.0655C121.366 34.8184 121.387 34.5598 121.356 34.3048Z"
                    fill="white"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M82.1854 108.594C82.7037 103.898 84.8143 99.5207 88.166 96.1903C91.5177 92.86 95.9085 90.7775 100.608 90.2892C105.308 89.801 110.033 90.9363 113.998 93.5065C117.962 96.0767 120.928 99.9267 122.401 104.416"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M84.4959 72.3518L76.915 81.6894"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M76.0447 73.238L85.3665 80.8031"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M121.15 68.5535L113.569 77.8753"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M112.699 69.4237L122.021 77.0046"
                    stroke="#D2E180"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_5226_65651"
                    x1="100.295"
                    y1="-11.0016"
                    x2="100.295"
                    y2="211.589"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.437233" stopColor="white" />
                    <stop offset="0.662338" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <clipPath id="clip0_5226_65651">
                    <rect width="200" height="200" fill="white" transform="translate(0 0.578369)" />
                </clipPath>
            </defs>
        </svg>
    );
};
