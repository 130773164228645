import styled from 'styled-components';

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 16px;
`;

export const Card = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 2px solid ${({ selected }) => (selected ? '#c0d330' : '#e5e5e5')};
    border-radius: 8px;
    background-color: ${({ selected }) => (selected ? 'rgba(192, 211, 48, 0.2)' : '#fff')};
    cursor: pointer;
    transition:
        border-color 0.3s,
        background-color 0.3s;

    &:hover {
        border-color: #c0d330;
    }
`;

export const Icon = styled.img`
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
`;

export const Label = styled.span`
    font-size: 14px;
    color: #333;
`;
