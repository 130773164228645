import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { SharedModal } from '../../sharedModal';
import { useLocales } from 'hooks/locales';
import { useDispatch } from 'react-redux';
import { setModalContent } from 'store/controlsSlice';
import * as Theme from './Theme';
import { router } from 'routers';
import { LabelInput } from 'components/labelInput';
import { FormRow } from 'components/modal-windows/FormRow';
import { MultiSelectController } from 'components/multi-selection';
import { arrayToSelectOptions, selectOptionsToValues } from 'libs/helpers';
import { Education, Gender, Nationality } from 'libs/enums';
import { InputDateController } from 'components/inputDate';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    UPDATE_PERSONAL_INFO_DEFAULTS,
    useUpdatePersonalInfoSchema,
} from 'schemas/club-coaches/useUpdatePersonalInfoSchema';
import {
    useEditPersonalInformation,
    useFetchPersonalInfoById,
} from 'services/hooks/administratorDetails';
import { useFetchTeamsTable } from 'services/hooks/teams/useFetchTeamsList';
import { EditCoachPersonalInformationRequest } from 'services/hooks/administratorDetails/useEditCoachPersonalInformation';
import { mappingOptionsToValues } from 'libs/helpers/selectionHelpers';
import { SaveLoaderButton } from 'components/saveLoaderButton';

interface EditPersonalInformationModalProps {
    isOpen: boolean;
    onClose: () => void;
    teamsData: any;
}

export const EditPersonalInformation: React.FC<EditPersonalInformationModalProps> = ({
    isOpen,
    onClose,
    teamsData,
}) => {
    const { trans } = useLocales();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        params: { sportId, id },
    } = router.getState();

    const { data, refetch } = useFetchPersonalInfoById(sportId, id);

    const schema = useUpdatePersonalInfoSchema();

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    console.log({ watcH: methods.watch(), form: methods.formState.errors });

    const { mutate } = useEditPersonalInformation(sportId, id, {
        onSuccess: (response) => {
            const isSuccess = [200, 201].includes(response.status);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'success',
                        title: trans('form.success'),
                        subtitle: isSuccess
                            ? trans('player.personalDataUpdatedSuccess')
                            : response.message || trans('form.error_occurred'),
                    },
                }),
            );

            onClose();
            refetch();
            setIsLoading(false);
        },
        onError: (error) => {
            setIsLoading(false);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'warning',
                        title: trans('form.warning'),
                        subtitle: error.message || trans('form.error_occurred'),
                    },
                }),
            );
        },
    });

    const handleSave = (_data: any) => {
        setIsLoading(true);
        const data = mappingOptionsToValues({ ..._data }, [
            'levelEducation',
            'gender',
            'nationality',
            'playingFor',
        ]) as EditCoachPersonalInformationRequest;

        mutate(data);
    };

    console.log('datadatadata', data, methods.watch());
    const { reset, control } = methods;
    useEffect(() => {
        console.log('from use effect');

        if (data?.payload) {
            const defaultValues = {
                firstName: data?.payload.firstName || '',
                lastName: data?.payload.lastName || '',
                gender:
                    arrayToSelectOptions({
                        array: Gender,
                        isEnum: true,
                        trans: trans,
                        transSuffix: 'form.editAthletePersonalInfo.',
                    }).find((option) => option.value === data?.payload.gender) || undefined,

                birthday: data?.payload.birthday || '',
                height: data?.payload.height || '',
                weight: data?.payload.weight || '',

                nationality:
                    arrayToSelectOptions({
                        array: Nationality,
                        isEnum: true,
                        trans: trans,
                        transSuffix: 'form.editAthleteProfile.',
                    }).find((option) => option.value === data?.payload.nationality) || undefined,

                levelEducation:
                    arrayToSelectOptions({
                        array: Education,
                        isEnum: true,
                        trans: trans,
                        transSuffix: 'form.editAthleteProfile.',
                    }).find((option) => option.value === data?.payload.levelEducation) || undefined,

                schoolName: data?.payload.schoolName || '',
                joinDate: data?.payload.joinDate || '',
                experience: data?.payload.experience || '',

                playingFor: data?.payload.playingFor
                    ? {
                          label: data?.payload.playingFor.name,
                          value: data?.payload.playingFor.id,
                      }
                    : undefined,
            };

            reset({
                ...defaultValues,
            });
        }
    }, [data, reset, trans]);

    return (
        <FormProvider {...methods}>
            <SharedModal
                isOpen={isOpen}
                onRequestClose={onClose}
                customHeight="100%"
                title={trans('player.Edit.personalInformation')}
            >
                <Theme.LineHR />
                <Theme.Body>
                    {/* First Name Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.firstName')} />
                        <Controller
                            name="firstName"
                            control={control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('player.Edit.firstName')}
                                />
                            )}
                        />
                        {methods.formState.errors.firstName && (
                            <Theme.ErrorText>
                                {methods.formState.errors.firstName.message}
                            </Theme.ErrorText>
                        )}
                    </Theme.InputsWrapper>

                    {/* Last Name Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.lastName')} />
                        <Controller
                            name="lastName"
                            control={control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('player.Edit.lastName')}
                                />
                            )}
                        />
                        {methods.formState.errors.lastName && (
                            <Theme.ErrorText>
                                {methods.formState.errors.lastName.message}
                            </Theme.ErrorText>
                        )}
                    </Theme.InputsWrapper>

                    {/* Join Date Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.joinDate')}
                            content={
                                <InputDateController
                                    control={control}
                                    name="joinDate"
                                    placeholder={trans('player.Edit.joinDate')}
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Experience Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.experience')} />
                        <Controller
                            name="experience"
                            control={control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('player.Edit.experience')}
                                />
                            )}
                        />
                        {methods.formState.errors.experience && (
                            <Theme.ErrorText>
                                {methods.formState.errors.experience.message}
                            </Theme.ErrorText>
                        )}
                    </Theme.InputsWrapper>

                    {/* Education Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.education')}
                            content={
                                <MultiSelectController
                                    control={control}
                                    name="levelEducation"
                                    options={arrayToSelectOptions({ array: Education })}
                                    transSuffix="form.editAthleteProfile."
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* School Name Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.schoolName')} />
                        <Controller
                            name="schoolName"
                            control={control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('player.Edit.schoolName')}
                                />
                            )}
                        />
                        {methods.formState.errors.schoolName && (
                            <Theme.ErrorText>
                                {methods.formState.errors.schoolName.message}
                            </Theme.ErrorText>
                        )}
                    </Theme.InputsWrapper>

                    {/* Date of Birth Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.dateOfBirth')}
                            content={
                                <InputDateController
                                    control={control}
                                    name="birthday"
                                    withPortal={true}
                                    placeholder={trans('player.Edit.dateOfBirth')}
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Gender Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.gender')}
                            content={
                                <MultiSelectController
                                    control={control}
                                    name="gender"
                                    options={arrayToSelectOptions({ array: Gender })}
                                    transSuffix="form.editAthletePersonalInfo."
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Playing for Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('coach.profile.playingFor')}
                            content={
                                <MultiSelectController
                                    control={control}
                                    name="playingFor"
                                    options={teamsData?.payload.items.map((item: any) => ({
                                        label: item.name,
                                        value: item.id,
                                    }))}
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Nationality Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.country')}
                            content={
                                <MultiSelectController
                                    control={control}
                                    name="nationality"
                                    options={arrayToSelectOptions({ array: Nationality })}
                                    transSuffix="form.editAthleteProfile."
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Weight Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.weight')} />
                        <Controller
                            name="weight"
                            control={control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    type="number"
                                    placeholder={trans('player.Edit.weight')}
                                />
                            )}
                        />
                        {methods.formState.errors.weight && (
                            <Theme.ErrorText>
                                {methods.formState.errors.weight.message}
                            </Theme.ErrorText>
                        )}
                    </Theme.InputsWrapper>

                    {/* Height Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.height')} />
                        <Controller
                            name="height"
                            control={control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    type="number"
                                    placeholder={trans('player.Edit.height')}
                                />
                            )}
                        />
                        {methods.formState.errors.height && (
                            <Theme.ErrorText>
                                {methods.formState.errors.height.message}
                            </Theme.ErrorText>
                        )}
                    </Theme.InputsWrapper>

                    <Theme.LineHR />
                    <Theme.InputMultiElemintsWrapperRight>
                        <Theme.SubmitButton
                            type="button"
                            onClick={methods.handleSubmit(handleSave)}
                        >
                            <>
                                {isLoading ? (
                                    <SaveLoaderButton
                                        spinnerColor="#C0D330"
                                        trackColor={'#C0D330'}
                                    />
                                ) : (
                                    <>
                                        <img
                                            src="/assets/icons/add-icon-colored.svg"
                                            height={20}
                                            width={20}
                                            alt="Save Icon"
                                        />
                                        {trans('player.Edit.personalInformation')}
                                    </>
                                )}
                            </>
                        </Theme.SubmitButton>
                    </Theme.InputMultiElemintsWrapperRight>
                </Theme.Body>
            </SharedModal>
        </FormProvider>
    );
};
