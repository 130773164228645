import React, { useEffect, useState } from 'react';
import { Table } from '../newSharedTable/NewSharedTable';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { router } from 'routers';
import { calculateYearsDifference, stringToDateString } from 'libs/helpers';
import { AdministratorModal } from 'components/administratorModals';

interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}

interface TemplatesTableProps {
    columns: Column[];
    data: any | { [key: string]: any }[];
}

export const TemplatesTableAdministrator: React.FC<TemplatesTableProps> = ({ columns, data }) => {
    const { trans } = useLocales();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        params: { sportId },
    } = router.getState();

    const redirect = (id: number) => {
        console.log(data);
        router.navigate(`administrator-details`, { id, sportId });
    };

    return (
        <div>
            <AdministratorModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <Theme.StatusBar>
                <Theme.UsersNumberTable>
                    {trans('table.all')}
                    {`(${data?.payload?.items?.length})`}
                </Theme.UsersNumberTable>
                <Theme.ButtonsWrapper onClick={() => setIsModalOpen(true)}>
                    <Theme.ButtonIcon
                        src="/assets/icons/add-icon.svg"
                        height={16}
                        width={16}
                        alt="Add Icon"
                    />
                    {trans('add.administrator')}
                </Theme.ButtonsWrapper>
            </Theme.StatusBar>

            <Table
                columns={columns.map((col) => ({
                    ...col,
                    label: col.label,
                }))}
                data={data?.payload?.items || []}
                renderRow={(row) => (
                    <Theme.TableRow key={row?.id} onClick={() => redirect(row?.id)}>
                        <Theme.TableCell>
                            <Theme.PersonInfoElement>
                                <Theme.PersonImage
                                    src="/assets/images/person-image.png"
                                    alt={`${row?.firstName} ${row?.lastName}`}
                                />
                                <Theme.PersonTextContainer>
                                    <Theme.PersonName>{`${row?.firstName} ${row?.lastName}`}</Theme.PersonName>
                                    <Theme.PersonAge>{`#${row?.id}`}</Theme.PersonAge>
                                </Theme.PersonTextContainer>
                            </Theme.PersonInfoElement>
                        </Theme.TableCell>
                        <Theme.TableCell>{row?.experience}</Theme.TableCell>
                        <Theme.TableCell>{trans(`form.addCoach.${row.type}`)}</Theme.TableCell>
                        <Theme.TableCell>
                            <span>{row?.gender}</span>,
                            <span>
                                {calculateYearsDifference(
                                    new Date(),
                                    new Date(row?.birthday || ''),
                                )}
                            </span>
                        </Theme.TableCell>
                        <Theme.TableCell>{row?.user?.email}</Theme.TableCell>
                    </Theme.TableRow>
                )}
            />
        </div>
    );
};
