import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

export interface EditCoachPersonalInformationRequest {
    firstName: string;
    lastName: string;
    joinDate: string;
    levelEducation: string;
    experience: number;
    schoolName: string;
    birthday: string;
    gender: string;
    nationality: string;
    playingFor: string;
    nin: string;
    ninExpirationDate: string;
    weight: number;
    height: number;
}

interface EditCoachPersonalInformation {
    message: string;
    status: number;
    payload: any;
}

export const editCoachPersonalInformation = async (
    sportId: string,
    coachId: string,
    data: EditCoachPersonalInformationRequest,
): Promise<EditCoachPersonalInformation> => {
    const response = await api
        .url(`sportClubProfile/${sportId}/club-admin/${coachId}/personal-information`)
        .patch(data)
        .json<EditCoachPersonalInformation>();
    return response;
};

export const useEditPersonalInformation = (
    sportId: string,
    coachId: string,
    options?: UseMutationOptions<
        EditCoachPersonalInformation,
        Error,
        EditCoachPersonalInformationRequest
    >,
): UseMutationResult<EditCoachPersonalInformation, Error, EditCoachPersonalInformationRequest> => {
    return useMutation<EditCoachPersonalInformation, Error, EditCoachPersonalInformationRequest>({
        mutationFn: (data: EditCoachPersonalInformationRequest) =>
            editCoachPersonalInformation(sportId, coachId, data),
        ...options,
    });
};
