import React, { useState, useEffect } from 'react';
import { SharedModal } from '../sharedModal';
import { useLocales } from 'hooks/locales';
import * as Theme from './Theme';
import { useRouter } from 'react-router5';
import { useDispatch } from 'react-redux';
import { FormRow } from 'components/modal-windows/FormRow';
import { addMonths } from 'libs/helpers';
import { InputDateController } from 'components/inputDate';
import { setModalContent } from 'store/controlsSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputController } from 'components/input';
import { useForm } from 'react-hook-form';
import { useNewYearGoalSchema } from 'schemas/goals/YearGoalValidationSchema';
import { AddYearGoalRequest, useYearGoal } from 'services/hooks/goals/useYearGoal';

interface SizeModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const YearGoalModal: React.FC<SizeModalProps> = ({ isOpen, onClose }) => {
    const { trans } = useLocales();
    const customHeight = 'auto';
    const dispatch = useDispatch();
    const [isReordered, setIsReordered] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const router = useRouter();
    const {
        params: { sportId },
    } = router.getState();

    const handleModalClose = () => {
        if (isReordered) {
            setShowWarning(true);
        } else {
            onClose();
            reset();
        }
    };
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const subGoalSchema = useNewYearGoalSchema();
    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'all',
        resolver: yupResolver(subGoalSchema),
    });

    const { mutateAsync } = useYearGoal(sportId);

    const handleSave = async (data: any) => {
        setIsSubmittingForm(true);
        const payload = {
            description: data.description,
            startYearGoal: data.startYearGoal,
            endYearGoal: data.endYearGoal,
        } as AddYearGoalRequest;
        try {
            const response = await mutateAsync(payload);
            if ([200, 201].includes(response.status)) {
                dispatch(
                    setModalContent({
                        modalContent: {
                            type: 'success',
                            title: trans('form.success'),
                            subtitle: trans('yearGoal.created_successfully'),
                        },
                    }),
                );
                // refetch();
                onClose();
                reset();
            } else {
                const errorMessage = response.message || trans('form.error_occurred');
                dispatch(
                    setModalContent({
                        modalContent: {
                            type: 'warning',
                            title: trans('form.warning'),
                            subtitle: errorMessage,
                        },
                    }),
                );
            }
        } catch (error: any) {
            onClose();
            console.error('Error occurred while saving yearGoal:', error);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'warning',
                        title: trans('form.warning'),
                        subtitle: trans(error),
                    },
                }),
            );
        } finally {
            setIsSubmittingForm(false);
        }
    };

    return (
        <>
            <SharedModal
                isOpen={isOpen}
                customHeight={customHeight}
                onRequestClose={handleModalClose}
                title={`${trans('add.year.goal')}`}
            >
                <Theme.LineHR />
                <Theme.Body>
                    <Theme.FullWidthInputsWrapperTwoInputs>
                        <FormRow
                            style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                            title={trans('teams.add..description')}
                            content={
                                <InputController
                                    type="text"
                                    {...{
                                        control,
                                        name: 'description',
                                        placeholder: trans('teams.add.description'),
                                    }}
                                />
                            }
                        />
                    </Theme.FullWidthInputsWrapperTwoInputs>
                    <Theme.FullWidthInputsWrapperTwoInputs>
                        <Theme.InputsWrapper>
                            <FormRow
                                style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                                title={trans('teams.add.startYearGoal')}
                                content={
                                    <InputDateController
                                        control={control}
                                        name="startYearGoal"
                                        maxDate={addMonths(new Date(), 100)}
                                        minDate={new Date()}
                                        placeholder={trans('teams.add.startYearGoal')}
                                    />
                                }
                            />
                        </Theme.InputsWrapper>
                        <Theme.InputsWrapper>
                            <FormRow
                                style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                                title={trans('teams.add.endYearGoal')}
                                content={
                                    <InputDateController
                                        control={control}
                                        name="endYearGoal"
                                        maxDate={addMonths(new Date(), 100)}
                                        minDate={new Date()}
                                        placeholder={trans('teams.add.endYearGoal')}
                                    />
                                }
                            />
                        </Theme.InputsWrapper>
                    </Theme.FullWidthInputsWrapperTwoInputs>
                    <Theme.InputMultiElemintsWrapperRight>
                        <Theme.SubmitButton type="submit" onClick={handleSubmit(handleSave)}>
                            <img
                                src="/assets/icons/save-icon.svg"
                                height={20}
                                width={20}
                                alt="Save Icon"
                            />
                            {isSubmittingForm ? trans('loading') : trans('goal.saveButton.modal')}
                        </Theme.SubmitButton>
                    </Theme.InputMultiElemintsWrapperRight>
                </Theme.Body>
            </SharedModal>
        </>
    );
};
