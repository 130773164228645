import React, { useState } from 'react';
import { SharedModal } from '../sharedModal';
import { useLocales } from 'hooks/locales';
import { AccountInformation } from './tabsComponents/AccountInformation';
import { PersonalInformation } from './tabsComponents/PersonalInformation';
import * as Theme from './Theme';
import { useForm, FormProvider } from 'react-hook-form';
import { useAddCoach } from 'services/hooks/coach/useAddCoach';
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent } from 'store/controlsSlice';
import { useFetchCoachesTable } from 'services/hooks/coach/useFetchCoachesTable';
import { useCoachValidationSchema } from 'schemas/coaches/createCoachSchemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { router } from 'routers';
import {
    DEFAULT_ADMINS_VALUES,
    useValidationSchemas,
} from '../../schemas/administrator/createAdministratorSchemas';
import {
    AddAdministratorsRequest,
    useAddAdministrators,
} from 'services/hooks/administrator/useAddAdministrators';
import { SaveLoaderButton } from 'components/saveLoaderButton';
interface CoachesModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const STEPS = [
    {
        key: 'personalInformation',
        label: 'tabs.personalInformation',
        component: PersonalInformation,
    },
    {
        key: 'accountInformation',
        label: 'tabs.accountInformation',
        component: AccountInformation,
    },
];

export const CoachesModal: React.FC<CoachesModalProps> = ({ isOpen, onClose }) => {
    const { trans } = useLocales();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const validationSchema = useValidationSchemas(STEPS[activeStep].key);
    const {
        params: { sportId },
    } = router.getState();

    const { refetch } = useFetchCoachesTable(sportId);

    const { mutateAsync } = useAddCoach(sportId);

    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_ADMINS_VALUES,
        mode: 'all',
    });

    const handleStepChange = async (nextStep: number) => {
        const currentStepFields = Object.keys(validationSchema.fields || {}) as string[];

        const valid = await methods.trigger(currentStepFields);

        if (valid) {
            setActiveStep(nextStep);
        } else {
            const firstInvalidField = currentStepFields.find(
                (field) => methods.formState.errors[field as string],
            ) as string;

            if (firstInvalidField) methods.setFocus(firstInvalidField);
        }
    };

    const handleSave = async (data: AddAdministratorsRequest) => {
        setIsLoading(true);
        const payload = {
            relationship: data.relationship.value,
            avatar: data.avatar,
            joinDate: data.joinDate,
            birthday: data.birthday || new Date(),
            phone: data.phone,
            type: data.type.value,
            emergencyPhone: data.emergencyPhone,
            nationality: data.nationality.value,
            gender: data.gender.value,
            experience: data.experience,
            email: data.email,
            username: data.username,
            password: data.password,
            branch: data.branch,
            lastName: data.lastName,
            firstName: data.firstName,
        };
        try {
            const payload = {
                relationship: data.relationship.value,
                avatar: data.avatar,
                joinDate: data.joinDate,
                birthday: data.birthday || new Date(),
                phone: data.phone,
                type: data.type.value,
                emergencyPhone: data.emergencyPhone,
                nationality: data.nationality.value,
                gender: data.gender.value,
                experience: data.experience,
                email: data.email,
                username: data.username,
                password: data.password,
                branch: data.branch,
                lastName: data.lastName,
                firstName: data.firstName,
            };
            const response = await mutateAsync(payload);

            if ([200, 201].includes(response.status)) {
                dispatch(
                    setModalContent({
                        modalContent: {
                            type: 'success',
                            title: trans('form.success'),
                            subtitle: trans('manager.created_successfully'),
                        },
                    }),
                );
                refetch();
                onClose();
                setIsLoading(false);
                methods.reset();
            } else {
                const errorMessage = response.message || trans('form.error_occurred');
                setIsLoading(false);
                dispatch(
                    setModalContent({
                        modalContent: {
                            type: 'warning',
                            title: trans('form.warning'),
                            subtitle: errorMessage,
                        },
                    }),
                );
            }
        } catch (error: any) {
            onClose();
            setIsLoading(false);
            console.error('Error occurred while saving administrator:', error);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'warning',
                        title: trans('form.warning'),
                        subtitle: trans(error),
                    },
                }),
            );
        }
    };

    const renderStepContent = () => {
        const StepComponent = STEPS[activeStep].component;
        return <StepComponent />;
    };

    return (
        <Theme.SharedModalStyled
            isOpen={isOpen}
            onRequestClose={onClose}
            title={trans('modal.addnew.coach')}
            footerContent={
                <Theme.FooterButtonsWrapper>
                    {activeStep > 0 && (
                        <Theme.NavButton onClick={() => handleStepChange(activeStep - 1)}>
                            {trans('button.previous')}
                        </Theme.NavButton>
                    )}
                    {activeStep < STEPS.length - 1 ? (
                        <Theme.SubmitButton
                            onClick={methods.handleSubmit(() => handleStepChange(activeStep + 1))}
                        >
                            {trans('button.next')}
                        </Theme.SubmitButton>
                    ) : (
                        <Theme.SubmitButton onClick={methods.handleSubmit(handleSave)}>
                            <>
                                {isLoading ? (
                                    <SaveLoaderButton />
                                ) : (
                                    <>
                                        <img
                                            src="/assets/icons/add-icon-colored.svg"
                                            height={20}
                                            width={20}
                                            alt="Save Icon"
                                        />
                                        {trans('button.save')}
                                    </>
                                )}
                            </>
                        </Theme.SubmitButton>
                    )}
                </Theme.FooterButtonsWrapper>
            }
        >
            <Theme.ModalContainer>
                <Theme.ProgressContainer>
                    {STEPS.map((step, index) => (
                        <Theme.ProgressStep
                            key={step.key}
                            isActive={index === activeStep}
                            isCompleted={index < activeStep}
                            onClick={() => handleStepChange(index)}
                        >
                            {trans(step.label)}
                        </Theme.ProgressStep>
                    ))}
                </Theme.ProgressContainer>

                <Theme.Body>
                    <FormProvider {...methods}>{renderStepContent()} </FormProvider>
                </Theme.Body>
            </Theme.ModalContainer>
        </Theme.SharedModalStyled>
    );
};
