import * as Yup from 'yup';
import { useLocales } from 'hooks/locales';

export const useManagerValidationSchema = () => {
    const { trans } = useLocales();

    const managerValidationSchema = Yup.object({
        firstName: Yup.string().required(trans('validation.firstNameRequired')),
        lastName: Yup.string().required(trans('validation.lastNameRequired')),
        nationality: Yup.object().required(trans('validation.nationalityRequired')),
        gender: Yup.object().required(trans('validation.genderRequired')),
        birthday: Yup.date().required(trans('validation.birthdayRequired')),
        phone: Yup.string()
            .matches(/^\d+$/, trans('validation.phoneMustBeNumeric'))
            .required(trans('validation.phoneRequired')),
        emergencyPhone: Yup.string()
            .matches(/^\d+$/, trans('validation.emergencyPhoneMustBeNumeric'))
            .required(trans('validation.emergencyPhoneRequired')),
        relationship: Yup.object().required(trans('validation.relationshipRequired')),
        educationalLevel: Yup.string(),
        experience: Yup.number()
            .min(0, trans('validation.experienceCannotBeNegative'))
            .required(trans('validation.experienceRequired')),
        nationalId: Yup.string().required(trans('validation.nationalIdRequired')),
        nationalIdExpirationDate: Yup.date().required(
            trans('validation.nationalIdExpirationDateRequired'),
        ),
        position: Yup.object().required(trans('validation.positionRequired')),
        joinDate: Yup.date().required(trans('validation.joinDateRequired')),
        contractRenewalTerms: Yup.string(),
        username: Yup.string().required(trans('validation.usernameRequired')),
        email: Yup.string()
            .email(trans('validation.invalidEmailFormat'))
            .required(trans('validation.emailRequired')),
        password: Yup.string()
            .min(8, trans('validation.passwordMinLength'))
            .required(trans('validation.passwordRequired')),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], trans('validation.passwordsMustMatch'))
            .required(trans('validation.confirmPasswordRequired')),
        avatar: Yup.mixed().required(trans('validation.avatarRequired')),
        contractType: Yup.object().required(trans('validation.contractRequired')),
        contractStartDate: Yup.date().required(trans('validation.contractStartDateRequired')),
        contractEndDate: Yup.date().required(trans('validation.contractEndDateRequired')),
        contractBenefits: Yup.string().required(trans('validation.contractBenefitsRequired')),
        contractDuration: Yup.number().required(trans('validation.contractDuration')),
    });

    return managerValidationSchema;
};
