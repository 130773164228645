// validationSchema.ts
import * as yup from 'yup';
import { Education, Gender, Nationality } from 'libs/enums';

// تعريف فالييدايشن لجميع الحقول
export const useUpdatePersonalInfoSchema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    joinDate: yup.date().required('Join date is required').typeError('Invalid date format'),
    level: yup.object().required('Level is required'),
    experience: yup.string().required('Experience is required'),
    education: yup.object().shape({
        value: yup.string().required('Education is required'),
    }),
    schoolName: yup.string().required('School name is required'),
    dateOfBirth: yup.date().required('Date of birth is required').typeError('Invalid date format'),
    gender: yup.object().shape({
        value: yup.string().required('Gender is required'),
    }),
    nationality: yup.object().shape({
        value: yup.string().required('Nationality is required'),
    }),
    nin: yup
        .string()
        .required('NIN is required')
        .matches(/^\d{10}$/, 'NIN must be 10 digits'),
    ninExpirationDate: yup
        .date()
        .required('NIN expiration date is required')
        .typeError('Invalid date format'),
    weight: yup.number().required('Weight is required').min(0, 'Weight must be positive'),
    height: yup.number().required('Height is required').min(0, 'Height must be positive'),
});
