import React, { useEffect, useState } from 'react';
import * as Theme from './theme';
import { useLocales } from 'hooks/locales';
import { useRoute } from 'react-router5';

interface MenuItem {
    name: string;
    iconPath: string;
    path: string;
    id: string;
    children?: MenuItem[];
}

interface DynamicAsideMenuProps {
    menuData: MenuItem[];
    onMenuSelect: (name: string, sportId: string) => void;
    menuOpen?: boolean;
}

export const DynamicAsideMenu: React.FC<DynamicAsideMenuProps> = ({
    menuData,
    menuOpen,
    onMenuSelect,
}) => {
    const { route } = useRoute();
    const [expandedMenus, setExpandedMenus] = useState<Record<string, boolean>>({});
    const [selectedMenu, setSelectedMenu] = useState<any>(null);
    const [selectedMenuId, setSelectedMenuId] = useState<any>(null);
    const { trans } = useLocales();
    const toggleMenu = (menuName: string) => {
        setExpandedMenus((prevState) => ({
            ...prevState,
            [menuName]: !prevState[menuName],
        }));
    };
    useEffect(() => {
        if (route?.path) {
            setSelectedMenu(route.path.split('/'));
            setSelectedMenuId(route?.params?.sportId);
        }
    }, [route]);

    const handleMenuClick = (menuPath: string, menuId: string) => {
        setSelectedMenu(menuPath);
        onMenuSelect(menuPath, menuId);
    };

    return (
        <Theme.MenuContainer>
            {menuData?.map((menuItem, index) => (
                <div key={index}>
                    <Theme.MenuItem
                        onClick={() => toggleMenu(menuItem.name)}
                        style={{
                            backgroundColor:
                                selectedMenuId === menuItem.id ? '#c0d33030' : 'transparent',
                        }}
                    >
                        <Theme.MenuIcon src={menuItem.iconPath} alt={menuItem.name} />
                        {menuOpen && (
                            <Theme.MenuText>{trans(`sport.${menuItem.name}`)}</Theme.MenuText>
                        )}
                        {menuItem.children &&
                            (expandedMenus[menuItem.name] ? (
                                <img
                                    src="/assets/icons/side-down-icon.svg"
                                    alt="dropdown icon"
                                    width={16}
                                    height={16}
                                    className="ms-[16px]"
                                />
                            ) : (
                                <img
                                    src="/assets/icons/side-up-icon.svg"
                                    alt="dropdown icon"
                                    width={16}
                                    height={16}
                                    className="ms-[16px]"
                                />
                            ))}
                    </Theme.MenuItem>

                    {menuItem?.children && expandedMenus?.[menuItem?.name] && (
                        <Theme.SubMenu>
                            {menuItem?.children?.map((childItem, childIndex) => (
                                <Theme.SubMenuItem
                                    key={childIndex}
                                    onClick={() => handleMenuClick(childItem?.path, menuItem?.id)}
                                >
                                    <Theme.MenuIcon
                                        src={childItem.iconPath}
                                        alt={childItem.name}
                                        style={{
                                            filter:
                                                childItem?.path &&
                                                selectedMenu.includes(childItem.path) &&
                                                selectedMenuId === menuItem.id
                                                    ? 'brightness(0) saturate(100%) invert(76%) sepia(43%) saturate(456%) hue-rotate(29deg) brightness(90%) contrast(86%)'
                                                    : 'none',
                                        }}
                                    />
                                    {menuOpen && (
                                        <Theme.MenuNestedText
                                            style={{
                                                color:
                                                    childItem?.path &&
                                                    selectedMenu.includes(childItem.path) &&
                                                    selectedMenuId === menuItem.id
                                                        ? '#c0d330'
                                                        : '',
                                            }}
                                        >
                                            {trans(childItem.name, {
                                                defaultValue: childItem.name,
                                            })}
                                        </Theme.MenuNestedText>
                                    )}
                                </Theme.SubMenuItem>
                            ))}
                        </Theme.SubMenu>
                    )}
                </div>
            ))}
        </Theme.MenuContainer>
    );
};
