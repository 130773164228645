import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'react-router5';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { useFetchStockClothes } from '../../services/hooks/clothe/useFetchStockClothes';
import { StockClothesTable } from '../../components/stockClothesTable';
import { StatusOfStockClothes } from '../../components/statusOfStockClothes';
import { Loader } from 'components';
import { DivWraper } from 'pages/coachDetails/components/ProfileSection/Theme';

export const StockClothes = () => {
    const { trans } = useLocales();
    const [columns, setColumns] = useState<string[]>([]);
    const [page, setPage] = useState(1);
    const [Limit, setLimit] = useState(50);
    const router = useRouter();
    const {
        params: { sportId },
    } = router.getState();

    const { data, isLoading, error } = useFetchStockClothes(sportId, page, Limit);

    useEffect(() => {
        setColumns([
            // trans('stockClothes.table.date'),
            trans('stockClothes.table.cloth_type'),
            trans('stockClothes.table.quantity_and_sizes'),
            trans('stockClothes.table.settings'),
        ]);
    }, [trans]);
    return (
        <DivWraper>
            <Theme.ComponantsWrapper>
                <StatusOfStockClothes />
            </Theme.ComponantsWrapper>
            {!data ? <Loader /> : <StockClothesTable columns={columns} data={data || []} />}
        </DivWraper>
    );
};
