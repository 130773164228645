import React from 'react';
import * as Theme from './Theme';
import NotificationMenu from './notifcationMenu/NotificationMenu';
import { ProfileMenu } from './profileMenu/profileMenu';
import { BreadCrumps } from 'components/base/breadCrumps';

export const Header: React.FC = () => {
    return (
        <Theme.Body>
            <Theme.CBody>
                <BreadCrumps />
            </Theme.CBody>
            <NotificationMenu />
            <ProfileMenu />
        </Theme.Body>
    );
};
