import { useLocales } from 'hooks/locales';
import * as yup from 'yup';

export const useNewYearGoalSchema = () => {
    const { trans } = useLocales();

    const newYearGoalValidators = {
        startYearGoal: yup
            .date()
            .required(
                trans('validation.newYearGoal.startYearGoalRequired') || 'Start date is required.',
            )
            .typeError(trans('validation.newYearGoal.invalidStartDate') || 'Invalid start date.'),
        endYearGoal: yup
            .date()
            .min(
                yup.ref('startYearGoal'),
                trans('validation.newYearGoal.endYearGoalAfterStart') ||
                    'End date must be after the start date.',
            )
            .required(
                trans('validation.newYearGoal.endYearGoalRequired') || 'End date is required.',
            )
            .typeError(trans('validation.newYearGoal.invalidEndDate') || 'Invalid end date.'),
        description: yup
            .string()
            .required(
                trans('validation.newYearGoal.descriptionRequired') || 'Description is required.',
            )
            .max(
                500,
                trans('validation.newYearGoal.descriptionTooLong') ||
                    'Description must be at most 500 characters.',
            ),
    };

    return yup.object().shape(newYearGoalValidators);
};
