import React, { useState } from 'react';
import { Table } from '../../newSharedTable/NewSharedTable';
import * as Theme from './Theme';
import { TeamsModal } from '../../teamsModal';
import { useLocales } from 'hooks/locales';
import { router } from 'routers';
import { calculateYearsDifference } from 'libs/helpers';
import { PlayerModal } from 'components/playerModal';

interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}

interface TemplatesTableProps {
    columns: Column[];
    data: any;
}

export const PlayersTable: React.FC<TemplatesTableProps> = ({ columns, data }) => {
    const { trans } = useLocales();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tabledata, setTabledata] = useState(data?.payload?.items || []);

    const {
        params: { sportId },
    } = router.getState();

    const redirect = (id: number) => {
        router.navigate(`team-details`, { id, sportId });
    };

    // Function to calculate min and max dates
    function getMinMaxBirthDates(athletes: any[]) {
        if (!athletes || athletes.length === 0) return { minDate: null, maxDate: null };
        const birthDates: any = athletes.map((athlete) => new Date(athlete.dateOfBirth));
        const minDate = new Date(Math.min(...birthDates));
        const maxDate = new Date(Math.max(...birthDates));
        return {
            minDate: minDate.toISOString().split('T')[0],
            maxDate: maxDate.toISOString().split('T')[0],
        };
    }

    return (
        <div>
            <PlayerModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <Theme.StatusBar>
                <Theme.UsersNumberTable>
                    {trans('table.all')}
                    {`(${tabledata?.length})`}
                </Theme.UsersNumberTable>
                <Theme.ButtonsWrapper type="button" onClick={() => setIsModalOpen(true)}>
                    <Theme.ButtonIcon
                        src="/assets/icons/add-icon.svg"
                        height={16}
                        width={16}
                        alt="Add Icon"
                    />
                    {trans('add.player')}
                </Theme.ButtonsWrapper>
            </Theme.StatusBar>

            <Table
                columns={columns.map((col) => ({
                    ...col,
                    label: col.label,
                }))}
                data={tabledata}
                renderRow={(row) => {
                    const { minDate, maxDate } = getMinMaxBirthDates(row?.athletes || []);
                    return (
                        <Theme.TableRow key={row?.id} onClick={() => redirect(row?.id)}>
                            <Theme.TableCell>
                                {row?.athletes[0]?.gender},
                                {calculateYearsDifference(new Date(), new Date(minDate || ''))}-
                                {calculateYearsDifference(new Date(), new Date(maxDate || ''))}
                            </Theme.TableCell>
                            <Theme.TableCell>
                                <Theme.SportSpan>sport name</Theme.SportSpan>
                            </Theme.TableCell>
                            <Theme.TableCell>
                                <span>{row?.athletes?.length}</span>
                            </Theme.TableCell>
                            <Theme.TableCell>
                                <span>{row?.athletes?.length}</span>/
                                <span>{row?.athletes?.length}</span>
                            </Theme.TableCell>
                            <Theme.TableCell>
                                {row?.coach ? (
                                    <Theme.PersonTextContainer>
                                        <Theme.PersonName>{`${row.coach.firstName} ${row.coach.lastName}`}</Theme.PersonName>
                                        <Theme.PersonAge>{`#${row?.coach?.id}`}</Theme.PersonAge>
                                    </Theme.PersonTextContainer>
                                ) : (
                                    <Theme.IconEmptyData
                                        src="/assets/icons/none-or-empty-data.svg"
                                        height={16}
                                        width={16}
                                        alt="Add Icon"
                                    />
                                )}
                            </Theme.TableCell>
                            <Theme.TableCell>
                                <Theme.IconEmptyData
                                    src="/assets/icons/none-or-empty-data.svg"
                                    height={16}
                                    width={16}
                                    alt="Add Icon"
                                />
                            </Theme.TableCell>
                            <Theme.TableCell>
                                <Theme.SessionsStatusDiv>
                                    <Theme.SpanContracter>
                                        {row?.sessions[0]?.status}
                                    </Theme.SpanContracter>
                                    <span>
                                        {row?.sessions[0]?.from}-{row?.sessions[0]?.to}
                                    </span>
                                </Theme.SessionsStatusDiv>
                            </Theme.TableCell>
                        </Theme.TableRow>
                    );
                }}
            />
        </div>
    );
};
