import React from 'react';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { Loader } from 'components/loader/Loader';
import { useRouter } from 'react-router5';
import { useFetchClothesSummarized } from 'services/hooks/clothe/useFetchClothesSummarized';

export const ClothesNeeded: React.FC = () => {
    const { trans } = useLocales();
    const router = useRouter();
    const {
        params: { sportId },
    } = router.getState();

    const { data, isLoading, isError } = useFetchClothesSummarized(sportId);

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <p>{trans('clothesNeeded.error')}</p>;
    }

    if (!data?.clothesNeeded?.length) {
        return null;
    }

    return (
        <Theme.ClothesNeededWrapper>
            <Theme.HeaderWrapper>
                <p>{trans('clothesNeeded.header.title')}</p>
                <Theme.DownloadButton>
                    <img src="/assets/icons/download-icon.svg" alt="Download" />
                </Theme.DownloadButton>
            </Theme.HeaderWrapper>

            <Theme.ClothesList>
                {data.clothesNeeded.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                        <Theme.ClothesItem>
                            <Theme.ItemName>{item?.category}:</Theme.ItemName>
                            <Theme.Sizes>
                                {item?.sizes.map((size: any, sizeIndex: number) => (
                                    <div key={sizeIndex}>
                                        <Theme.SpanSummarizedNum>
                                            {size?.quantity}
                                        </Theme.SpanSummarizedNum>
                                        <Theme.SpanSummarizedSize>
                                            {size?.size}
                                        </Theme.SpanSummarizedSize>
                                    </div>
                                ))}
                            </Theme.Sizes>
                        </Theme.ClothesItem>
                    </React.Fragment>
                ))}
            </Theme.ClothesList>
        </Theme.ClothesNeededWrapper>
    );
};
