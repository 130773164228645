import { ClubCard } from '../club-item/ClubCard';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { Loader } from 'components';
import { useClubList } from 'services/hooks/clubProfile/useClubList';
import { useSelector } from 'react-redux';
import { selectAcademy } from 'store';
import { AddNewSportModal } from '../addNewSportModal/AddNewSportModal';

export type SportType = 'football' | 'swimming' | 'athletics';

export const sportMapping: Record<SportType, string> = {
    football: '/assets/icons/sports/football-icon.svg',
    swimming: '/assets/icons/sports/swimming-icon.svg',
    athletics: '/assets/icons/sports/athletics-icon.svg',
};

export const ClubList = () => {
    const { trans } = useLocales();
    const { academy } = useSelector(selectAcademy);
    const { data, isLoading, error } = useClubList(academy.id);

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <div>{trans('errorFetchingData')}</div>;
    }

    const clubs = data?.payload || [];

    return (
        <Theme.Body>
            <Theme.Header>
                <Theme.Title>{trans('clubList.title')}</Theme.Title>
                <AddNewSportModal />
            </Theme.Header>

            <Theme.List>
                {clubs?.length > 0 ? (
                    clubs?.map?.((club: any) => (
                        <ClubCard
                            key={club.id}
                            club={{
                                id: club.id,
                                name: trans(`sport.${club.sport}`, club.sport),
                                avatar:
                                    sportMapping[club.sport as SportType] ||
                                    '/images/default-club-icon.png', // Fallback to default icon
                                metrics: {
                                    teams: club.teamsCount || 0,
                                    coaches: club.coachesCount || 0,
                                    athletes: club.athletesCount || 0,
                                    administrators: 0,
                                    medicalTeam: 0,
                                    supportTeam: 0,
                                },
                                managers: {
                                    sportProfileManager: 'N/A',
                                    technicalDirector: 'N/A',
                                },
                            }}
                        />
                    ))
                ) : (
                    <div>{trans('g.nodata')}</div>
                )}
            </Theme.List>
        </Theme.Body>
    );
};
