import { memo, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header } from '../../components/base/header';
import { Aside } from '../../components/base/aside';
import { Modal } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { selectControls } from 'store';
import { StoreState } from 'libs/types';
import { selectAcademy, setAcademy } from 'store/academySlice';

interface DashboardLayoutProps {
    children: ReactNode;
}

const Main = styled.main`
    display: flex;
    min-height: 100vh;
    min-width: 360px;
    width: 100%;
    height: 100%;
`;

const Content = styled.div.attrs<any>((props) => ({
    className: 'relative flex flex-col',
    dir: props.direction || 'ltr',
}))`
    width: ${(props: any) => (props.menuOpen ? '80%' : '100%')};
    height: 100%;
    margin: ${(props: any) => (props.menuOpen ? '88px 0 0 20vw' : '88px 0 0 80px')};

    &[dir='rtl'] {
        margin: ${(props: any) => (props.menuOpen ? '88px 20vw 0 0' : '88px 80px 0 0')};
    }

    @media (max-width: 1024px) {
        margin: 88px 0 0 0;
        width: 100%;
        &[dir='rtl'] {
            margin: 88px 0 0 0;
        }
    }
`;
export const DashboardLayout = memo(({ children }: DashboardLayoutProps) => {
    const { modalContent } = useSelector(selectControls);
    const dispatch = useDispatch();
    const user = useSelector((state: StoreState) => state.auth?.entities);
    const { isRTL } = useSelector<any>((state) => state?.locales) as any;
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    useEffect(() => {
        if (user?.academy) dispatch(setAcademy({ academy: user.academy }));
    }, [user, dispatch]);

    return (
        <Main>
            {modalContent.type !== 'none' && <Modal />}
            <Aside toggleMenu={toggleMenu} menuOpen={menuOpen} />
            <Header />
            <Content direction={isRTL ? 'rtl' : 'ltr'} menuOpen={menuOpen}>
                {children}
            </Content>
        </Main>
    );
});
