import React from 'react';
import { useForm, FormProvider, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SharedModal } from '../../sharedModal';
import { useLocales } from 'hooks/locales';
import { useDispatch, useSelector } from 'react-redux';
import { selectControls, setModalContent } from 'store/controlsSlice';
import * as Theme from './Theme';
import { router } from 'routers';
import { LabelInput } from 'components/labelInput';
import { FormRow } from 'components/modal-windows/FormRow';
import { MultiSelectController } from 'components/multi-selection';
import { Divider } from 'components/modal-windows';
import { arrayToSelectOptions } from 'libs/helpers';
import { Bank, useCreateBankDataSchema } from 'schemas/athlete/addAtheleteBank';
import { useGetAthletes } from 'hooks';
import { useUpdatePlayerBankData } from 'services/hooks/players/useEditPlayerBankData';
import { useCreatePlayerBankData } from 'services/hooks/players/useEditPlayerBankData';
import { useFetchAthleteDetailsById } from 'services/hooks';

interface EditPLayerBankDataModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface EditPLayerBankDataForm {
    iban: string;
    bank: any;
    accountHolder: string;
}

export const EditPlayerBankData: React.FC<EditPLayerBankDataModalProps> = ({ isOpen, onClose }) => {
    const { trans } = useLocales();
    const dispatch = useDispatch();
    const { modalContent } = useSelector(selectControls);
    const {
        params: { id },
    } = router.getState();

    const { data: athlete, isLoading } = useGetAthletes<any>({
        id: id || '',
        idType: 'athlete',
        dependents: [modalContent],
    });

    const validationSchema = useCreateBankDataSchema();

    // Initialize form methods with validation schema
    const methods = useForm<EditPLayerBankDataForm>({
        mode: 'all',
        defaultValues: {
            iban: athlete?.bankDetails?.iban || '',
            bank: athlete?.bankDetails?.bank || '',
            accountHolder: athlete?.bankDetails?.accountHolder || '',
        },
        resolver: yupResolver(validationSchema),
    });

    const { control } = methods;

    const { refetch } = useFetchAthleteDetailsById(id || '');
    const { mutate: updateBankDataMutation } = useUpdatePlayerBankData(athlete?.id);
    const { mutate: createBankDataMutation } = useCreatePlayerBankData(athlete?.id);

    const handleSave: SubmitHandler<EditPLayerBankDataForm> = (data) => {
        const isCreate = !athlete?.bankDetails;

        const mutation = isCreate ? createBankDataMutation : updateBankDataMutation;

        mutation({
            iban: data.iban,
            bank: data.bank.value,
            accountHolder: data.accountHolder,
        });

        // Dispatch success or error response based on mutation
        mutation(
            {
                iban: data.iban,
                bank: data.bank.value,
                accountHolder: data.accountHolder,
            },
            {
                onSuccess: (response: any) => {
                    const isSuccess = [200, 201].includes(response.status);
                    dispatch(
                        setModalContent({
                            modalContent: {
                                type: isSuccess ? 'success' : 'warning',
                                title: isSuccess ? trans('form.success') : trans('form.warning'),
                                subtitle: isSuccess
                                    ? trans('player.bankDataUpdatedSuccess')
                                    : response.message || trans('form.error_occurred'),
                            },
                        }),
                    );

                    if (isSuccess) {
                        refetch();
                        onClose();
                    }
                },
                onError: (error: Error) => {
                    dispatch(
                        setModalContent({
                            modalContent: {
                                type: 'warning',
                                title: trans('form.warning'),
                                subtitle: error.message || trans('form.error_occurred'),
                            },
                        }),
                    );
                },
            },
        );
    };

    return (
        <FormProvider {...methods}>
            <SharedModal
                isOpen={isOpen}
                onRequestClose={onClose}
                title={trans('player.Edit.bankData')}
            >
                <Theme.LineHR />
                <Theme.Body>
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.iban')} />
                        <Controller
                            name="iban"
                            control={control}
                            render={({ field, fieldState }) => (
                                <>
                                    <Theme.Input
                                        {...field}
                                        placeholder={trans('player.Edit.iban')}
                                    />
                                    {fieldState.error && (
                                        <span style={{ color: 'red' }}>
                                            {fieldState.error.message}
                                        </span>
                                    )}
                                </>
                            )}
                        />
                    </Theme.InputsWrapper>

                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.bank')}
                            content={
                                <MultiSelectController
                                    control={control}
                                    name="bank"
                                    options={arrayToSelectOptions({ array: Bank })}
                                    transSuffix="bank."
                                />
                            }
                        />
                        <Divider />
                    </Theme.InputsWrapper>

                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.accountHolder')} />
                        <Controller
                            name="accountHolder"
                            control={control}
                            render={({ field, fieldState }) => (
                                <>
                                    <Theme.Input
                                        {...field}
                                        placeholder={trans('player.Edit.accountHolder')}
                                    />
                                    {fieldState.error && (
                                        <span style={{ color: 'red' }}>
                                            {fieldState.error.message}
                                        </span>
                                    )}
                                </>
                            )}
                        />
                    </Theme.InputsWrapper>

                    <Theme.LineHR />
                    <Theme.InputMultiElemintsWrapperRight>
                        <Theme.SubmitButton
                            type="button"
                            onClick={methods.handleSubmit(handleSave)}
                        >
                            <img
                                src="/assets/icons/add-icon-colored.svg"
                                height={20}
                                width={20}
                                alt="Save Icon"
                            />
                            {trans('player.Edit.dataBank')}
                        </Theme.SubmitButton>
                    </Theme.InputMultiElemintsWrapperRight>
                </Theme.Body>
            </SharedModal>
        </FormProvider>
    );
};
