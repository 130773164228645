import React from 'react';
import * as Theme from './Theme';
import { useFormContext, Controller } from 'react-hook-form';
import { useLocales } from 'hooks/locales';
import { LabelInput } from '../../labelInput';
import 'react-datepicker/dist/react-datepicker.css';
import { Gender, Nationality, Relationship, Education } from 'libs/enums';
import { MultiSelectController } from 'components/multi-selection';
import { FormRow } from 'components/modal-windows/FormRow';
import { addMonths, arrayToSelectOptions } from 'libs/helpers';
import { InputDateController } from 'components/inputDate';
import { Divider } from 'components/modal-windows';
import { FilePickerController } from 'components/filePicker/FilePickerController';
import { AdministratorsType } from 'libs/enums/athlete';

interface FormData {
    firstName: string;
    lastName: string;
    avatar: File | null;
    nationality: any;
    gender: any;
    birthday: Date | null;
    joinDate: Date | null;
    emergencyPhone: string;
    phone: string;
    type: any;
    relationship: any;
    experience: number;
    branch?: string;
}

export const PersonalInformation: React.FC = () => {
    const { trans } = useLocales();
    const {
        control,
        formState: { errors },
    } = useFormContext<FormData>();
    return (
        <Theme.Body>
            <Theme.GridWrapper>
                {/* Full Name and Image */}
                <Theme.InputsWrapper>
                    <LabelInput label={trans('label.firstName')} />
                    <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                            <>
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('placeholder.firstName')}
                                    required
                                />
                                {errors.firstName && (
                                    <Theme.ErrorText>{errors.firstName.message}</Theme.ErrorText>
                                )}
                            </>
                        )}
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <LabelInput label={trans('label.lastName')} />
                    <Controller
                        control={control}
                        name="lastName"
                        render={({ field }) => (
                            <>
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('placeholder.lastName')}
                                    required
                                />
                                {errors.lastName && (
                                    <Theme.ErrorText>{errors.lastName.message}</Theme.ErrorText>
                                )}
                            </>
                        )}
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.avatar')}
                        // subtitle={trans('form.manager.profile.image')}
                        content={
                            <FilePickerController
                                {...{
                                    control,
                                    name: 'avatar',
                                    placeholder: trans('placeholder.avatar'),
                                }}
                            />
                        }
                    />
                </Theme.InputsWrapper>

                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.nationality')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'nationality',
                                    options: arrayToSelectOptions({ array: Nationality }),
                                    transSuffix: 'form.editAthleteProfile.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.gender')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'gender',
                                    options: arrayToSelectOptions({ array: Gender }),
                                    transSuffix: 'form.editAthletePersonalInfo.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.InputsWrapper>
                {/* Birthday */}
                <Theme.FullWidthInputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.birthday')}
                        content={
                            <InputDateController
                                control={control}
                                name="birthday"
                                placeholder={trans('placeholder.birthday')}
                            />
                        }
                    />
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.joinDate')}
                        content={
                            <InputDateController
                                control={control}
                                name="joinDate"
                                placeholder={trans('placeholder.joinDate')}
                            />
                        }
                    />
                    <Divider />
                </Theme.FullWidthInputsWrapper>
                <Theme.FullWidthInputsWrapper>
                    <LabelInput label={trans('label.contactNumber')} />
                    <Controller
                        control={control}
                        name="phone"
                        rules={{ required: trans('error.contactNumber') }}
                        render={({ field }) => (
                            <>
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('placeholder.contactNumber')}
                                />
                            </>
                        )}
                    />
                    <Divider />
                </Theme.FullWidthInputsWrapper>
                <Theme.InputsWrapper>
                    <LabelInput label={trans('label.emergencyPhone')} />
                    <Controller
                        control={control}
                        name="emergencyPhone"
                        rules={{ required: trans('error.emergencyPhone') }}
                        render={({ field, fieldState }) => (
                            <>
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('placeholder.emergencyPhone')}
                                    required
                                />
                                {fieldState?.error?.message && (
                                    <Theme.ErrorText>{fieldState.error.message}</Theme.ErrorText>
                                )}
                            </>
                        )}
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.relationship')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'relationship',
                                    options: arrayToSelectOptions({ array: Relationship }),
                                    transSuffix: 'form.editAthleteProfile.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.InputsWrapper>
                <Theme.FullWidthInputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.type')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'type',
                                    options: arrayToSelectOptions({ array: AdministratorsType }),
                                    transSuffix: 'form.type.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.FullWidthInputsWrapper>
                {/* National ID and Expiration Date */}
                <Theme.InputsWrapper>
                    <LabelInput label={trans('label.experience')} />
                    <Controller
                        control={control}
                        name="experience"
                        rules={{ required: trans('error.experience') }}
                        render={({ field, fieldState }) => (
                            <>
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('placeholder.experience')}
                                />
                            </>
                        )}
                    />
                </Theme.InputsWrapper>
            </Theme.GridWrapper>
        </Theme.Body>
    );
};
