import React, { useState, useEffect } from 'react';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { useFetchStockClothesStatus } from 'services/hooks/clothe/useFetchStockClothesStatus';
import { useRouter } from 'react-router5';
import { Loader } from 'components/loader';
import { TemplatesTableClothes } from '../../components/templatesTableClothes';
import { ClothesNeeded } from '../../components/clothesNeeded/ClothesNeeded';
import { StatusOfClothes } from '../../components/statusOfClthes';

export const Clothes = () => {
    const { trans } = useLocales();
    const [columns, setColumns] = useState<string[]>([]);
    const router = useRouter();
    const {
        params: { sportId },
    } = router.getState();
    const { data, isLoading, isError } = useFetchStockClothesStatus(sportId);

    useEffect(() => {
        setColumns([
            trans('clothes.table.player'),
            trans('clothes.table.category'),
            trans('clothes.table.joined_date'),
            trans('clothes.table.clothes'),
            trans('clothes.table.requirements'),
        ]);
    }, [trans]);

    const handleRedirect = () => {
        router.navigate(`stock-clothes`, { sportId });
    };

    return (
        <>
            <Theme.ContanerButtonsWrapper>
                <Theme.ButtonsWrapper onClick={handleRedirect}>
                    {trans('view.stock')}
                </Theme.ButtonsWrapper>
            </Theme.ContanerButtonsWrapper>
            <Theme.ComponantsWrapper>
                <ClothesNeeded />
                <Theme.DivWrapperClothes>
                    <StatusOfClothes />
                </Theme.DivWrapperClothes>
            </Theme.ComponantsWrapper>
            {isLoading ? <Loader /> : <TemplatesTableClothes columns={columns} data={data.data} />}
        </>
    );
};
