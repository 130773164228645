import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, Controller, SubmitHandler } from 'react-hook-form';
import { SharedModal } from '../../sharedModal';
import { useLocales } from 'hooks/locales';
import { useDispatch, useSelector } from 'react-redux';
import { selectControls, setModalContent } from 'store/controlsSlice';
import * as Theme from './Theme';
import { router } from 'routers';
import { LabelInput } from 'components/labelInput';
import { FormRow } from 'components/modal-windows/FormRow';
import { MultiSelectController } from 'components/multi-selection';
import { arrayToSelectOptions } from 'libs/helpers';
import { Divider } from 'components/modal-windows';
import { Relationship, SubscriptionPeriod } from 'libs/enums';
import {
    useCreatePlayerContact,
    useUpdatePlayerContact,
} from 'services/hooks/players/useEditPlayerContact';
import { InputDateController } from 'components/inputDate';
import {
    useFetchCoachDetailsById,
    useFetchContactInfoById,
    useFetchPersonalInfoById,
    useUpdateCoachContact,
} from 'services/hooks/coachDetails';
import { SaveLoaderButton } from 'components/saveLoaderButton';

interface EditContactModalProps {
    isOpen: boolean;
    onClose: () => void;
    contactInfo: any;
}

interface EditContactForm {
    nationalId: string;
    nationalIdExpiration: string;
    phone: string;
    emergencyPhone: string;
    joinDate: string;
    notes: string;
    durationPeriod: any;
}

export const EditContact: React.FC<EditContactModalProps> = ({ isOpen, onClose }) => {
    const { trans } = useLocales();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { modalContent } = useSelector(selectControls);
    const {
        params: { sportId, id },
    } = router.getState();

    const methods = useForm<EditContactForm>({
        mode: 'all',
        // resolver: yupResolver(validationSchema),
    });
    const { control, reset } = methods;

    const { data, refetch } = useFetchContactInfoById(sportId, id);
    const { mutate } = useUpdateCoachContact(sportId, id, {
        onSuccess: (response) => {
            const isSuccess = [200, 201].includes(response.status);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'success',
                        title: trans('form.success'),
                        subtitle: isSuccess
                            ? trans('player.contactDataUpdatedSuccess')
                            : response.message || trans('form.error_occurred'),
                    },
                }),
            );

            onClose();
            refetch();
            setIsLoading(false);
        },
        onError: (error) => {
            setIsLoading(false);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'warning',
                        title: trans('form.warning'),
                        subtitle: error.message || trans('form.error_occurred'),
                    },
                }),
            );
        },
    });

    const handleSave: SubmitHandler<EditContactForm> = (_data) => {
        setIsLoading(true);
        const contactData = {
            nationalId: _data.nationalId,
            nationalIdExpiration: _data.nationalIdExpiration,
            phone: _data.phone,
            emergencyPhone: _data.emergencyPhone,
            joinDate: _data.joinDate,
            notes: _data.notes,
            durationPeriod: _data.durationPeriod.value,
        };

        mutate(contactData);
    };

    useEffect(() => {
        if (data) {
            const durationPeriodOptions = arrayToSelectOptions({
                array: SubscriptionPeriod,
                isEnum: true,
                trans: trans,
                transSuffix: 'form.subscriptionManagement.periodSubscription.',
            });

            const selectedDurationPeriod = durationPeriodOptions.find(
                (option) => option.value === data?.payload?.contractDetails?.durationPeriod,
            );

            reset({
                nationalId: data?.payload?.contractDetails?.nationalId || '',
                nationalIdExpiration: data?.payload?.contractDetails?.nationalIdExpiration || '',
                phone: data?.payload?.contractDetails?.phone || '',
                emergencyPhone: data?.payload?.contractDetails?.emergencyPhone || '',
                joinDate: data?.payload?.contractDetails?.joinDate || '',
                notes: data?.payload?.contractDetails?.notes || '',
                durationPeriod: selectedDurationPeriod || '',
            });
        }
    }, [data, reset, trans]);

    return (
        <FormProvider {...methods}>
            <SharedModal
                isOpen={isOpen}
                onRequestClose={onClose}
                customHeight="100%"
                title={trans('player.Edit.contactData')}
            >
                <Theme.LineHR />
                <Theme.Body>
                    {/* Phone Number Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('coach.profile.Edit.phoneNumber')} />
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Theme.Input
                                        {...field}
                                        placeholder={trans('coach.profile.Edit.phoneNumber')}
                                        required
                                    />
                                    {methods.formState.errors.phone && (
                                        <Theme.ErrorText>
                                            {methods.formState.errors.phone.message}
                                        </Theme.ErrorText>
                                    )}
                                </>
                            )}
                        />
                    </Theme.InputsWrapper>

                    {/*Urgent Phone Number Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('coach.profile.Edit.urgentPhoneNumber')} />
                        <Controller
                            name="emergencyPhone"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Theme.Input
                                        {...field}
                                        placeholder={trans('coach.profile.Edit.urgentPhoneNumber')}
                                        required
                                    />
                                    {methods.formState.errors.emergencyPhone && (
                                        <Theme.ErrorText>
                                            {methods.formState.errors.emergencyPhone.message}
                                        </Theme.ErrorText>
                                    )}
                                </>
                            )}
                        />
                    </Theme.InputsWrapper>

                    {/*national Id Number Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('coach.profile.Edit.nationalId')} />
                        <Controller
                            name="nationalId"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Theme.Input
                                        {...field}
                                        placeholder={trans('coach.profile.Edit.nationalId')}
                                        required
                                    />
                                    {methods.formState.errors.nationalId && (
                                        <Theme.ErrorText>
                                            {methods.formState.errors.nationalId.message}
                                        </Theme.ErrorText>
                                    )}
                                </>
                            )}
                        />
                    </Theme.InputsWrapper>

                    {/* national Id Expiration Date Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('coach.profile.Edit.nationalIdExpiration')}
                            content={
                                <InputDateController
                                    control={methods.control}
                                    name="nationalIdExpiration"
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Join Date Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.joinDate')}
                            content={
                                <InputDateController control={methods.control} name="joinDate" />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/*notes Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('coach.profile.Edit.notes')} />
                        <Controller
                            name="notes"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Theme.Input
                                        {...field}
                                        placeholder={trans('coach.profile.Edit.notes')}
                                        required
                                    />
                                    {methods.formState.errors.nationalId && (
                                        <Theme.ErrorText>
                                            {methods.formState.errors.nationalId.message}
                                        </Theme.ErrorText>
                                    )}
                                </>
                            )}
                        />
                    </Theme.InputsWrapper>
                    {/*duration Period Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.durationPeriod')}
                            content={
                                <MultiSelectController
                                    control={control}
                                    name="durationPeriod"
                                    options={arrayToSelectOptions({ array: SubscriptionPeriod })}
                                    transSuffix="form.subscriptionManagement.periodSubscription."
                                />
                            }
                        />
                    </Theme.InputsWrapper>
                    <Theme.LineHR />
                    <Theme.InputMultiElemintsWrapperRight>
                        <Theme.SubmitButton
                            type="button"
                            onClick={methods.handleSubmit(handleSave)}
                        >
                            <>
                                {isLoading ? (
                                    <SaveLoaderButton
                                        spinnerColor="#C0D330"
                                        trackColor={'#C0D330'}
                                    />
                                ) : (
                                    <>
                                        <img
                                            src="/assets/icons/add-icon-colored.svg"
                                            height={20}
                                            width={20}
                                            alt="Save Icon"
                                        />
                                        {trans('player.Edit.dataBank')}
                                    </>
                                )}
                            </>
                        </Theme.SubmitButton>
                    </Theme.InputMultiElemintsWrapperRight>
                </Theme.Body>
            </SharedModal>
        </FormProvider>
    );
};
