import { useLocales } from 'hooks/locales';
import * as yup from 'yup';
export interface AddSubGoalRequest {
    description: string;
    months: object;
    missionOne: string;
    unitNumber: number;
    unit: object;
    sessionsNeeded: number;
    missionTwo: string;
    unitNumberTwo: number;
    unitTwo: object;
    sessionsNeededTwo: number;
    missionThree: string;
    unitNumberThree: number;
    unitThree: object;
    sessionsNeededThree: number;
    missionFour: string;
    unitNumberFour: number;
    unitFour: object;
    sessionsNeededFour: number;
}

export const useNewSubGoalSchema = () => {
    const { trans } = useLocales();

    const newSubGoalValidators = {
        months: yup.object().required(trans('validation.newSubGoal.monthsRequired')),
        description: yup
            .string()
            .required(
                trans('validation.newSubGoal.descriptionRequired') || 'Description is required.',
            )
            .max(
                500,
                trans('validation.newSubGoal.descriptionTooLong') ||
                    'Description must be at most 500 characters.',
            ),
        missionOne: yup
            .string()
            .required(
                trans('validation.newSubGoal.missionOneRequired') || 'Mission One is required.',
            ),
        unitNumber: yup
            .number()
            .required(
                trans('validation.newSubGoal.unitNumberRequired') || 'Unit number is required.',
            )
            .min(
                0,
                trans('validation.newSubGoal.unitNumberMin') || 'Unit number must be at least 0.',
            ),
        unit: yup
            .object()
            .required(trans('validation.newSubGoal.unitRequired') || 'Unit is required.'),

        sessionsNeeded: yup
            .number()
            .required(
                trans('validation.newSubGoal.sessionsNeededRequired') ||
                    'Sessions needed is required.',
            )
            .min(
                0,
                trans('validation.newSubGoal.sessionsNeededMin') ||
                    'Sessions needed must be at least 0.',
            )
            .max(
                6,
                trans('validation.newSubGoal.sessionsNeededMax') ||
                    'Sessions needed must not exceed 6.',
            ),

        missionTwo: yup.string().nonNullable(),
        unitNumberTwo: yup
            .number()
            .min(
                0,
                trans('validation.newSubGoal.unitNumberTwoMin') ||
                    'Unit number (Mission Two) must be at least 0.',
            )
            .nonNullable(),
        unitTwo: yup.object().nonNullable(),
        sessionsNeededTwo: yup
            .number()
            .min(
                0,
                trans('validation.newSubGoal.sessionsNeededTwoMin') ||
                    'Sessions needed (Mission Two) must be at least 0.',
            )
            .max(
                6,
                trans('validation.newSubGoal.sessionsNeededTwoMax') ||
                    'Sessions needed (Mission Two) must not exceed 6.',
            )
            .nonNullable(),

        missionThree: yup.string().nonNullable(),
        unitNumberThree: yup
            .number()
            .min(
                0,
                trans('validation.newSubGoal.unitNumberThreeMin') ||
                    'Unit number (Mission Three) must be at least 0.',
            )
            .nonNullable(),
        unitThree: yup.object().nonNullable(),
        sessionsNeededThree: yup
            .number()
            .min(
                0,
                trans('validation.newSubGoal.sessionsNeededThreeMin') ||
                    'Sessions needed (Mission Three) must be at least 0.',
            )
            .max(
                6,
                trans('validation.newSubGoal.sessionsNeededThreeMax') ||
                    'Sessions needed (Mission Three) must not exceed 6.',
            )
            .nonNullable(),

        missionFour: yup.string().nonNullable(),
        unitNumberFour: yup
            .number()
            .min(
                0,
                trans('validation.newSubGoal.unitNumberFourMin') ||
                    'Unit number (Mission Four) must be at least 0.',
            )
            .nonNullable(),
        unitFour: yup.object().nonNullable(),
        sessionsNeededFour: yup
            .number()
            .min(
                0,
                trans('validation.newSubGoal.sessionsNeededFourMin') ||
                    'Sessions needed (Mission Four) must be at least 0.',
            )
            .max(
                6,
                trans('validation.newSubGoal.sessionsNeededFourMax') ||
                    'Sessions needed (Mission Four) must not exceed 6.',
            )
            .nonNullable(),
    };

    return yup.object().shape(newSubGoalValidators);
};
