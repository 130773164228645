import styled from 'styled-components';
import { Image as DefaultImage } from 'components';

export const Body = styled.div`
    height: 100vh;
    width: 17px;
`;

export const Logo = styled(DefaultImage)`
    height: 100vh;
`;
