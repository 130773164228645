import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

interface AddManagersRequest {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    nationality: string;
    gender: string;
    birthday: Date;
    phone: string;
    emergencyPhone: string;
    relationship: string;
    educationalLevel: string;
    experience: number;
    nationalId: string;
    nationalIdExpirationDate: Date;
    position: string;
    joinDate: Date | null;
    contractRenewalTerms: string;
    avatar: File | null;
    contractDuration: number;
    contractType: string;
    contractStartDate: Date;
    contractEndDate: Date;
    contractBenefits: string;
}

interface AddManagers {
    message: string;
    status: number;
    payload: any;
}

export const addManagers = async (
    clubId: string,
    data: AddManagersRequest,
): Promise<AddManagers> => {
    const response = await api.url(`club/${clubId}/manager`).post(data).json<AddManagers>();
    return response;
};

export const useAddManagers = (
    clubId: string,
    options?: UseMutationOptions<AddManagers, Error, AddManagersRequest>,
): UseMutationResult<AddManagers, Error, AddManagersRequest> => {
    return useMutation<AddManagers, Error, AddManagersRequest>({
        mutationFn: (data: AddManagersRequest) => addManagers(clubId, data),
        ...options,
    });
};
