import React, { useEffect, useState } from 'react';
import * as Theme from './Theme';
import { useFormContext, Controller } from 'react-hook-form';
import { useLocales } from 'hooks/locales';
import { LabelInput } from '../../labelInput';
import { AgeGroups, SkillLevel, SubscriptionPeriod, YesNo } from 'libs/enums';
import { FormRow } from 'components/modal-windows/FormRow';
import { MultiSelectController } from 'components/multi-selection';
import { arrayToSelectOptions } from 'libs/helpers';
import { InputDateController } from 'components/inputDate';
import { Divider } from 'components/modal-windows';
import { useSelector } from 'react-redux';
import { selectAcademy } from 'store';
import { useClubList } from 'services/hooks/clubProfile/useClubList';
import {
    FootballPosition,
    SwimmingDistance,
    KarateBelt,
    TaekwondoBelt,
    JudoBelt,
    JujutsuBelt,
    FutsalPosition,
    BeachVolleyballEvent,
    PadelEvent,
    TriathlonEvent,
    BalootEvent,
    WrestlingEvent,
    MuayThaiEvent,
    BoxingEvent,
    AthleticsEvent,
} from '../../../libs/enums';
import { router } from 'routers';
export const FileInformation: React.FC = () => {
    const { trans } = useLocales();
    const [postionPlayersGameOptions, setPostionPlayersGameOptions] = useState<any>();
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { academy } = useSelector(selectAcademy);
    const { data, isPending } = useClubList(academy.id);
    const {
        params: { sportId },
    } = router.getState();
    useEffect(() => {
        if (data?.payload) {
            const filteredSport = data.payload.find((club) => club?.id === sportId);
            if (filteredSport) {
                console.log('Sport Name:', filteredSport?.sport);
                switch (filteredSport.sport) {
                    case 'football':
                        setPostionPlayersGameOptions(FootballPosition);
                        break;
                    case 'athletics':
                        setPostionPlayersGameOptions(AthleticsEvent);
                        break;
                    case 'swimming':
                        setPostionPlayersGameOptions(SwimmingDistance);
                        break;
                    case 'karate':
                        setPostionPlayersGameOptions(KarateBelt);
                        break;
                    case 'taekwondo':
                        setPostionPlayersGameOptions(TaekwondoBelt);
                        break;
                    case 'judo':
                        setPostionPlayersGameOptions(JudoBelt);
                        break;
                    case 'jujutsu':
                        setPostionPlayersGameOptions(JujutsuBelt);
                        break;
                    case 'futsal':
                        setPostionPlayersGameOptions(FutsalPosition);
                        break;
                    case 'beachVolleyball':
                        setPostionPlayersGameOptions(BeachVolleyballEvent);
                        break;
                    case 'padel':
                        setPostionPlayersGameOptions(PadelEvent);
                        break;
                    case 'triathlon':
                        setPostionPlayersGameOptions(TriathlonEvent);
                        break;
                    case 'baloot':
                        setPostionPlayersGameOptions(BalootEvent);
                        break;
                    case 'wrestling':
                        setPostionPlayersGameOptions(WrestlingEvent);
                        break;
                    case 'muayThai':
                        setPostionPlayersGameOptions(MuayThaiEvent);
                        break;
                    case 'boxing':
                        setPostionPlayersGameOptions(BoxingEvent);
                        break;
                    default:
                        setPostionPlayersGameOptions([]);
                        break;
                }
            } else {
                console.log('No sport found with the given ID.');
                setPostionPlayersGameOptions([]);
            }
        }
    }, [data, sportId]);
    return (
        <Theme.Body>
            <Theme.GridWrapper>
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.joinedDate')}
                        content={
                            <InputDateController
                                control={control}
                                name="joinDate"
                                placeholder={trans('placeholder.joinedDate')}
                            />
                        }
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.periodOfSubscription')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'periodOfSubscription',
                                    options: arrayToSelectOptions({ array: SubscriptionPeriod }),
                                    transSuffix: 'form.subscriptionManagement.periodSubscription.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.position.new')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'position',
                                    options: arrayToSelectOptions({
                                        array: postionPlayersGameOptions || {},
                                    }),
                                    transSuffix: 'form.add.player.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.category')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'category',
                                    options: arrayToSelectOptions({ array: AgeGroups }),
                                    transSuffix: 'form.subscriptionManagement.periodSubscription.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.level')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'clublevel',
                                    options: arrayToSelectOptions({ array: SkillLevel }),
                                    transSuffix: 'form.add.player.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <LabelInput label={trans('label.weight')} />
                    <Controller
                        control={control}
                        name="weight"
                        render={({ field }) => (
                            <>
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('placeholder.firstName')}
                                    required
                                />
                            </>
                        )}
                    />
                </Theme.InputsWrapper>
            </Theme.GridWrapper>
        </Theme.Body>
    );
};
