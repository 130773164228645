interface ClubProfile {
    id: string;
    sport: string;
    avatarUrl: string;
}

export const remapClubProfilesToMenuItems = (response: {
    message: string;
    payload: ClubProfile[];
    status: number;
}): any[] => {
    const { payload = [] } = response;

    return payload?.map?.((clubProfile) => ({
        name: clubProfile.sport,
        id: clubProfile?.id,
        iconPath: '/assets/icons/menu-icon.svg',
        children: [
            // {
            //     name: 'menu.teams',
            //     iconPath: '/assets/icons/menu/teams-icon.svg',
            //     path: 'club-team',
            // },
            {
                name: 'menu.teams',
                iconPath: '/assets/icons/menu/teams-icon.svg',
                path: 'teams',
            },
            // {
            //     name: 'menu.players',
            //     iconPath: '/assets/icons/menu/players-icon.svg',
            //     path: 'club-athlete',
            // },
            {
                name: 'menu.players',
                iconPath: '/assets/icons/menu/players-icon.svg',
                path: 'players',
            },
            // {
            //     name: 'menu.coaches',
            //     iconPath: '/assets/icons/menu/coaches-icon.svg',
            //     path: 'club-coach',
            // },
            {
                name: 'menu.coaches',
                iconPath: '/assets/icons/menu/coaches-icon.svg',
                path: 'coaches',
            },
            {
                name: 'menu.administrators',
                iconPath: '/assets/icons/menu/administrators-icon.svg',
                path: 'administrator',
            },
            {
                name: 'menu.medicalTeam',
                iconPath: '/assets/icons/menu/medical-team-icon.svg',
                path: '',
            },
            {
                name: 'menu.supportTeam',
                iconPath: '/assets/icons/menu/support-icon.svg',
                path: '',
            },
            {
                name: 'menu.clothes',
                iconPath: '/assets/icons/menu/clothes-icon.svg',
                path: 'club-clothes',
            },
        ],
    }));
};
