import { useLocales } from 'hooks/locales';
// this import for the useFetchManagersTable hook API call
import { useFetchCoachesTable } from '../../services/hooks/coach/useFetchCoachesTable';
import { TemplatesTableCoaches } from '../../components/templatesTableCoaches';
import { useState, useEffect } from 'react';
import { Loader } from 'components';
import { router } from 'routers';
interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}
export const Coaches = () => {
    const { trans } = useLocales();
    const [columns, setColumns] = useState<Column[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const {
        params: { sportId },
    } = router.getState();
    // this is the API call for the get coaches table
    const { data, isLoading, error } = useFetchCoachesTable(sportId, page, limit);
    useEffect(() => {
        setColumns([
            {
                key: 'coach',
                label: trans('coach.table.coach'),
                width: '20%',
                sortable: false,
            },
            {
                key: 'experiences',
                label: trans('coach.table.experiences'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'type',
                label: trans('coach.table.type'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'team category',
                label: trans('coach.table.teamCategory'),
                width: '15%',
                sortable: false,
            },
            {
                key: 'contact',
                label: trans('coach.table.contact'),
                width: '15%',
                sortable: false,
            },
            {
                key: 'contract',
                label: trans('coach.table.contract'),
                width: '15%',
                sortable: true,
            },
        ]);
    }, [trans]);

    if (error) return <div>Error: {error.message}</div>;
    return (
        <>
            {isLoading ? <Loader /> : <TemplatesTableCoaches columns={columns} data={data || []} />}
        </>
    );
};
