import { useLocales } from 'hooks/locales';
import { TemplatesTablePlayer } from '../../components/templatesTablePlayer';
import { useState, useEffect } from 'react';
import { Loader } from 'components';
import { useSelector } from 'react-redux';
import { selectAcademy } from 'store';
import { useFetchPlayersTable } from 'services/hooks/players/useFetchPlayersTable';
import { useRouter } from 'react-router5';
interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}
export const Players = () => {
    const { trans } = useLocales();
    const [columns, setColumns] = useState<Column[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const { academy } = useSelector(selectAcademy);
    const router = useRouter();
    const {
        params: { sportId },
    } = router.getState();
    const { data, isLoading, error } = useFetchPlayersTable(sportId, page, limit);
    useEffect(() => {
        setColumns([
            {
                key: 'player',
                label: trans('player.table.player'),
                width: '20%',
                sortable: false,
            },
            {
                key: 'category',
                label: trans('player.table.category'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'team',
                label: trans('player.table.team'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'position',
                label: trans('player.table.position'),
                width: '15%',
                sortable: false,
            },
            {
                key: 'weight',
                label: trans('player.table.weight'),
                width: '15%',
                sortable: false,
            },
            {
                key: 'contract',
                label: trans('player.table.contract'),
                width: '15%',
                sortable: true,
            },
        ]);
    }, [trans]);
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div style={{ width: '100%' }}>
            {isLoading ? <Loader /> : <TemplatesTablePlayer columns={columns} data={data || []} />}
        </div>
    );
};
