import React, { useEffect, useState } from 'react';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { PlayersTable } from 'components/templatesTablesTeamDetails/PlayersTable';

interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}
export const Players: React.FC = () => {
    const [columns, setColumns] = useState<Column[]>([]);
    const { trans } = useLocales();
    useEffect(() => {
        setColumns([
            {
                key: 'player',
                label: trans('player.table.player'),
                width: '20%',
                sortable: false,
            },
            {
                key: 'level',
                label: trans('player.table.level'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'positions',
                label: trans('player.table.positions'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'weight',
                label: trans('player.table.weight'),
                width: '15%',
                sortable: false,
            },
            {
                key: 'contract',
                label: trans('player.table.contract'),
                width: '15%',
                sortable: true,
            },
        ]);
    }, [trans]);
    return (
        <Theme.Body>
            <PlayersTable columns={columns || []} data={[]} />
        </Theme.Body>
    );
};
