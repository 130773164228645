import React from 'react';
import { useController, Control } from 'react-hook-form';
import * as Theme from './theme';

interface SportsSelectionControllerProps {
    name: string;
    control: Control<any>;
    options: { label: string; value: string; icon: string }[];
}

export const SelectionCardController: React.FC<SportsSelectionControllerProps> = ({
    name,
    control,
    options,
}) => {
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
    });

    const handleSelect = (selectedValue: string) => {
        onChange(selectedValue);
    };

    return (
        <Theme.Grid>
            {options.map((option) => (
                <Theme.Card
                    key={option.value}
                    selected={value === option.value}
                    onClick={() => handleSelect(option.value)}
                >
                    <Theme.Icon src={option.icon} alt={option.label} />
                    <Theme.Label>{option.label}</Theme.Label>
                </Theme.Card>
            ))}
        </Theme.Grid>
    );
};
