/* eslint-disable prettier/prettier */

export const AcademyIcon = ({ color }: { color: string }) => (
    <svg
        width="116"
        height="116"
        viewBox="0 0 116 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M24.6154 40.7692H16.1346C12.3859 40.7692 8.79069 39.2942 6.13993 36.6687C3.48918 34.0432 2 30.4822 2 26.7692C2 23.0561 3.48918 19.4952 6.13993 16.8697C8.79069 14.2442 12.3859 12.7692 16.1346 12.7692H24.6154"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M91.3848 40.7692H99.8655C103.614 40.7692 107.209 39.2942 109.86 36.6687C112.511 34.0432 114 30.4822 114 26.7692C114 23.0561 112.511 19.4952 109.86 16.8697C107.209 14.2442 103.614 12.7692 99.8655 12.7692H91.3848"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.769 114H103.231"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M47.2308 73.0769V86.1232C47.2308 89.1897 44.5313 91.5871 41.6595 92.8694C34.882 95.8801 30 104.187 30 114"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M68.769 73.0769V86.1232C68.769 89.1897 71.4685 91.5871 74.3403 92.8694C81.1178 95.8801 85.9998 104.187 85.9998 114"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M91.3845 5.99999C91.3845 3.79086 89.5936 2 87.3845 2H28.6152C26.4061 2 24.6152 3.79086 24.6152 6V41.432C24.6152 50.396 28.1325 58.9928 34.3934 65.3313C40.6542 71.6698 49.1457 75.2308 57.9998 75.2308C66.854 75.2308 75.3455 71.6698 81.6063 65.3313C87.8672 58.9928 91.3845 50.396 91.3845 41.432V5.99999Z"
            fill={color}
            fillOpacity="0.08"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
