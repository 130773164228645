import React, { useState } from 'react';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { SizeModal } from '../sizeModal';

interface TemplatesTableProps {
    columns: string[];
    data: { [key: string]: any }[];
}

export const TemplatesTableClothes: React.FC<TemplatesTableProps> = ({ columns, data }) => {
    const { trans } = useLocales();
    const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
    const [notDelivered, setNotDelivered] = useState<Set<number>>(new Set());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [athleteId, setAthleteId] = useState<string>('');
    const [dataRow, setDataRow] = useState();

    const toggleRowSelection = (rowId: number) => {
        setSelectedRows((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(rowId)) {
                newSet.delete(rowId);
            } else {
                newSet.add(rowId);
            }
            return newSet;
        });
    };
    const notDeliveredCount = notDelivered.size;

    const handleDataWithModal = (athleteId: string, row: any) => {
        setIsModalOpen(true);
        setAthleteId(athleteId);
        setDataRow(row);
    };

    const getRandomColor = () => {
        const colors = ['#6941C614', '#175CD314', '#3538CD14', '#34405414'];

        const randomColor = colors[Math.floor(Math.random() * colors.length)];

        const darkenColor = (hexColor: string) => {
            const hex = hexColor.replace('#', '');
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);

            const darkenFactor = 0.6;
            const darkenedColor = [
                Math.max(0, r * darkenFactor) & 0xff,
                Math.max(0, g * darkenFactor) & 0xff,
                Math.max(0, b * darkenFactor) & 0xff,
            ]
                .map((x) => x.toString(16).padStart(2, '0'))
                .join('');

            return `#${darkenedColor}`;
        };

        const darkerColor = darkenColor(randomColor.replace(/14$/, ''));
        return { randomColor, darkerColor };
    };
    return (
        <div>
            <SizeModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                athleteId={athleteId}
                userDataRow={dataRow}
            />
            <Theme.StatusBar>
                <Theme.UsersNumberTable>
                    {trans('table.all')}
                    {`(${data?.length})`}
                </Theme.UsersNumberTable>
                {/* <Theme.ButtonsWrapper onClick={() => setNotDelivered(new Set())}>
                    {trans('all.is.good')}
                </Theme.ButtonsWrapper>
                <Theme.ButtonsWrapper
                    onClick={() => setNotDelivered(new Set(data.map((_, index) => index)))}
                >
                    {trans('not.delivered')}
                </Theme.ButtonsWrapper> */}
                {/* <Theme.NotDeliveredPara>{`Not Delivered: ${notDeliveredCount}`}</Theme.NotDeliveredPara> */}
            </Theme.StatusBar>
            <Theme.TableWrapper>
                <thead>
                    <tr>
                        {columns.map((column: any) => (
                            <Theme.TableHeader key={`${column}${column.length}`}>
                                {column}
                            </Theme.TableHeader>
                        ))}
                        <Theme.TableHeader></Theme.TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <Theme.TableRow key={`${row}${rowIndex}`}>
                            <Theme.TableCell>
                                <Theme.PersonInfoElement>
                                    <Theme.PersonImage
                                        src="/assets/images/person-image.png"
                                        alt={row.name}
                                    />
                                    <Theme.PersonTextContainer>
                                        <Theme.PersonName>{row.name}</Theme.PersonName>
                                        <Theme.PersonAge>Age: 16</Theme.PersonAge>
                                    </Theme.PersonTextContainer>
                                </Theme.PersonInfoElement>
                            </Theme.TableCell>
                            <Theme.TableCell>
                                <p>{row.category}</p>
                            </Theme.TableCell>
                            <Theme.TableCell>
                                <p>{row.joinedDate}</p>
                            </Theme.TableCell>
                            <Theme.TableCell>
                                <Theme.ClothesMissingWrapper>
                                    {row.requirements.map((item: any, index: number) => {
                                        const { randomColor, darkerColor } = getRandomColor();
                                        return (
                                            <Theme.ParaMissingClothes
                                                key={`${item.size}-${item.shortage}-${index}`}
                                                style={{
                                                    backgroundColor: randomColor,
                                                    borderRadius: '12px',
                                                    margin: '0px 5px',
                                                    padding: '4px 6px',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                <span style={{ opacity: '0.5' }}>
                                                    {item.size} - {item.shortage}
                                                </span>
                                            </Theme.ParaMissingClothes>
                                        );
                                    })}
                                </Theme.ClothesMissingWrapper>
                            </Theme.TableCell>
                            <Theme.TableCell>
                                {row.status === 'NEEDS' ? (
                                    <Theme.Li style={{ color: '#FF002E' }}>
                                        {trans('NEEDS')}
                                    </Theme.Li>
                                ) : null}
                                {row.status === 'ALL_IS_GOOD' ? (
                                    <Theme.Li style={{ color: '#039855' }}>
                                        {trans('all.is.good')}
                                    </Theme.Li>
                                ) : null}
                                {row.status === 'NOT_DELIVERED' ? (
                                    <Theme.Li style={{ color: '#FFC000' }}>
                                        {trans('not.delivered')}
                                    </Theme.Li>
                                ) : null}
                            </Theme.TableCell>
                            <Theme.TableCell>
                                <button onClick={() => handleDataWithModal(row.athleteId, row)}>
                                    <img
                                        src="/assets/icons/edit.svg"
                                        height={16}
                                        width={16}
                                        alt={row.name}
                                    />
                                </button>
                            </Theme.TableCell>
                        </Theme.TableRow>
                    ))}
                </tbody>
            </Theme.TableWrapper>
        </div>
    );
};
