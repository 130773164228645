import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

interface CoachTableRequest {
    sportId: string;
    page: number;
    limit: number;
}

interface CoachesTable {
    id: string;
    name: string;
    quantity: number;
    size: string[];
}

export const fetchCoachesTable = async ({
    sportId,
    page,
    limit,
}: CoachTableRequest): Promise<CoachesTable[]> => {
    const response = await api
        .url(`sportClubProfile/${sportId}/club-coaches?page=${page}&limit=${limit}&sortOrder=ASC`)
        .get()
        .json<any>();
    return response.payload.items;
};

export const useFetchCoachesTable = (
    sportId: string,
    page: number = 1,
    limit: number = 10,
    options?: UseQueryOptions<CoachesTable[], Error>,
): UseQueryResult<CoachesTable[], Error> => {
    return useQuery<CoachesTable[], Error>({
        queryKey: ['fetchCoachesTable'],
        queryFn: () => fetchCoachesTable({ sportId, page, limit }),
        staleTime: 0,
        ...options,
    });
};
