import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { SharedModal } from '../../sharedModal';
import { useLocales } from 'hooks/locales';
import { useDispatch, useSelector } from 'react-redux';
import { selectControls, setModalContent } from 'store/controlsSlice';
import { MultiSelectController } from 'components/multi-selection';
import { InputPdfController } from 'components/inputFilesController';
import { arrayToSelectOptions } from 'libs/helpers';
import { YesNo, Consideration } from 'libs/enums';
import { FoodAllergies } from 'libs/enums/athlete';
import * as Theme from './Theme';
import { useUpdateMedicalInfo } from 'services/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useGetAthletes } from 'hooks';
import { router } from 'routers';
import { LabelInput } from 'components/labelInput';

interface MedicalInfoFormData {
    allergies: any;
    chronicDisease: any;
    injury: any;
    foodAllergies: any;
    consideration: any;
    foodAllergiesFile?: File;
    currentConsiderationFile?: File;
}

interface MedicalInfoModalProps {
    isOpen: boolean;
    onClose: () => void;
    defaultValues?: MedicalInfoFormData;
}

export const MedicalInfoModal: React.FC<MedicalInfoModalProps> = ({
    isOpen,
    onClose,
    defaultValues,
}) => {
    const { trans } = useLocales();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { modalContent } = useSelector(selectControls);
    const {
        params: { id },
    } = router.getState();

    const { data: athlete, isLoading } = useGetAthletes<any>({
        id: id || '',
        idType: 'athlete',
        dependents: [modalContent],
    });
    const updateMedicalInfoMutation = useUpdateMedicalInfo(athlete?.id);
    const methods = useForm<MedicalInfoFormData>({
        mode: 'all',
        defaultValues: defaultValues || {},
    });

    const handleSave: SubmitHandler<MedicalInfoFormData> = async (data) => {
        const formData = new FormData();

        // Append form data fields
        formData.append('allergies', data.allergies?.value || '');
        formData.append('chronicDisease', data.chronicDisease?.value || '');
        formData.append('foodAllergies', data.foodAllergies?.value || '');
        formData.append('consideration', data.consideration?.value || '');
        formData.append('foodAllergiesFile', data.foodAllergiesFile || '');
        formData.append('currentConsiderationFile', data.currentConsiderationFile || '');

        updateMedicalInfoMutation.mutate(formData, {
            onSuccess: (response) => {
                const isSuccess = [200, 201].includes(response.status);
                queryClient.invalidateQueries({ queryKey: ['medicalInfo'] });

                dispatch(
                    setModalContent({
                        modalContent: {
                            type: isSuccess ? 'success' : 'warning',
                            title: isSuccess ? trans('form.success') : trans('form.warning'),
                            subtitle: isSuccess
                                ? trans('medicalInfo.updatedSuccessfully')
                                : response.message || trans('form.error_occurred'),
                        },
                    }),
                );
                if (isSuccess) onClose();
            },
            onError: (error: Error) => {
                dispatch(
                    setModalContent({
                        modalContent: {
                            type: 'warning',
                            title: trans('form.warning'),
                            subtitle: error.message || trans('form.error_occurred'),
                        },
                    }),
                );
            },
        });
    };
    const customHeight = '100%';
    return (
        <FormProvider {...methods}>
            <SharedModal
                isOpen={isOpen}
                onRequestClose={onClose}
                customHeight={customHeight}
                title={trans('medicalInfo.editTitle')}
            >
                <Theme.Body>
                    <Theme.LineHR />
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.edit.allergies')} />
                        <MultiSelectController
                            name="allergies"
                            control={methods.control}
                            options={arrayToSelectOptions({ array: YesNo })}
                            transSuffix="form.editMedicalInfo."
                        />
                    </Theme.InputsWrapper>

                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.edit.chronicDisease')} />
                        <MultiSelectController
                            name="chronicDisease"
                            control={methods.control}
                            options={arrayToSelectOptions({ array: YesNo })}
                            transSuffix="form.editMedicalInfo."
                        />
                    </Theme.InputsWrapper>

                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.edit.foodAllergies')} />
                        <MultiSelectController
                            name="foodAllergies"
                            control={methods.control}
                            options={arrayToSelectOptions({ array: FoodAllergies })}
                            transSuffix="foodAllergies."
                        />
                    </Theme.InputsWrapper>

                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.edit.consideration')} />
                        <MultiSelectController
                            name="consideration"
                            control={methods.control}
                            options={arrayToSelectOptions({ array: Consideration })}
                            transSuffix="form.editMedicalInfo."
                        />
                    </Theme.InputsWrapper>

                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.edit.foodAllergiesFile')} />
                        <InputPdfController
                            name="foodAllergiesFile"
                            control={methods.control}
                            contents={
                                <Theme.UploadText>
                                    <span>{trans('form.editAthleteProfile.uploadText1')}</span>{' '}
                                    {trans('form.editAthleteProfile.uploadText2')}
                                    <br />
                                    {trans('form.editAthleteProfile.uploadText4')}
                                </Theme.UploadText>
                            }
                        />
                    </Theme.InputsWrapper>

                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.edit.currentConsiderationFile')} />
                        <InputPdfController
                            name="currentConsiderationFile"
                            control={methods.control}
                            contents={
                                <Theme.UploadText>
                                    <span>{trans('form.editAthleteProfile.uploadText1')}</span>{' '}
                                    {trans('form.editAthleteProfile.uploadText2')}
                                    <br />
                                    {trans('form.editAthleteProfile.uploadText4')}
                                </Theme.UploadText>
                            }
                        />
                    </Theme.InputsWrapper>

                    <Theme.LineHR />
                    <Theme.InputMultiElemintsWrapperRight>
                        <Theme.SubmitButton
                            type="button"
                            onClick={methods.handleSubmit(handleSave)}
                        >
                            <img
                                src="/assets/icons/add-icon-colored.svg"
                                height={20}
                                width={20}
                                alt="Save Icon"
                            />
                            {trans('form.save')}
                        </Theme.SubmitButton>
                    </Theme.InputMultiElemintsWrapperRight>
                </Theme.Body>
            </SharedModal>
        </FormProvider>
    );
};
