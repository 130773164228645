import React from 'react';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';

interface AuthBannerProps {}

export const AuthFrame: React.FC<React.HTMLAttributes<HTMLDivElement> & AuthBannerProps> = () => {
    const { trans } = useLocales();

    return (
        <Theme.Body>
            <Theme.Logo src="/assets/images/signup-frame.png" alt="logo" />
        </Theme.Body>
    );
};
