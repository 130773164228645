import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';
import { Consideration, Education, Gender, Nationality, Relationship } from 'libs/enums';

export interface AddAdministratorsRequest {
    relationship: any;
    avatar?: any | null;
    joinDate: Date | null;
    birthday: Date | null;
    phone: string;
    type: any;
    emergencyPhone: string;
    nationality: any;
    gender: any;
    lastName: string;
    firstName: string;
    experience: number;
    email: string;
    username: string;
    password: string;
    branch?: string;
}

interface AddAdministrators {
    message: string;
    status: number;
    payload: any;
}

export const addAdministrators = async (
    sportId: string,
    data: AddAdministratorsRequest,
): Promise<AddAdministrators> => {
    const response = await api
        .url(`sportClubProfile/${sportId}/club-admin`)
        .post(data)
        .json<AddAdministrators>();
    return response;
};

export const useAddAdministrators = (
    sportId: string,
    options?: UseMutationOptions<AddAdministrators, Error, AddAdministratorsRequest>,
): UseMutationResult<AddAdministrators, Error, AddAdministratorsRequest> => {
    return useMutation<AddAdministrators, Error, AddAdministratorsRequest>({
        mutationFn: (data: AddAdministratorsRequest) => addAdministrators(sportId, data),
        ...options,
    });
};
