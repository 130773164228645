import React from 'react';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';

export const HeaderTeam: React.FC = () => {
    const { trans } = useLocales();

    return (
        <Theme.Body>
            <Theme.HeaderTeamWrapper>
                <div
                    style={{
                        display: 'flex',
                        width: 'auto',
                    }}
                >
                    <div
                        style={{
                            margin: '0px 50px',
                        }}
                    >
                        <Theme.CenteredColumn>
                            <img
                                src="/assets/icons/helal-Club.svg"
                                height={160}
                                width={160}
                                alt="logo"
                            />
                        </Theme.CenteredColumn>
                        <Theme.CreatedDate>
                            {trans('header.teamDetails.created')}: 1 Jan, 2021
                        </Theme.CreatedDate>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}
                    >
                        <div>
                            <Theme.TeamName>Al Hilal</Theme.TeamName>
                            <Theme.TeamDetails>(male, 12)</Theme.TeamDetails>
                        </div>
                        <Theme.DetailRow>
                            <Theme.DetailLabel>
                                <Theme.Icon src="/assets/icons/address-icon.svg" alt="Address" />
                            </Theme.DetailLabel>
                            <Theme.DetailLabel>
                                {trans('header.teamDetails.address')}:
                            </Theme.DetailLabel>
                            <Theme.DetailValue>99, ElZahara, Gada</Theme.DetailValue>
                        </Theme.DetailRow>
                        <Theme.DetailRow>
                            <Theme.DetailLabel>
                                <Theme.Icon src="/assets/icons/players-icon.svg" alt="Players" />
                            </Theme.DetailLabel>
                            <Theme.DetailLabel>
                                {trans('header.teamDetails.players')}:
                            </Theme.DetailLabel>
                            <Theme.PlayerBadge>15 Short Distances</Theme.PlayerBadge>
                            <Theme.PlayerBadge
                                style={{ color: '#34dc25', backgroundColor: '#34dc250a' }}
                            >
                                13 Mid Distances
                            </Theme.PlayerBadge>
                            <Theme.PlayerBadge
                                style={{ color: '#202020', backgroundColor: '#2020200a' }}
                            >
                                12 Long Distances
                            </Theme.PlayerBadge>
                        </Theme.DetailRow>
                        <div>
                            <Theme.ButtonsWrapper
                                type="button"
                                onClick={() => console.log('ddddd')}
                            >
                                <Theme.ButtonIcon
                                    src="/assets/icons/edit.svg"
                                    height={16}
                                    width={16}
                                    alt="Edit Icon"
                                />
                                {trans('clubInfo.editButton')}
                            </Theme.ButtonsWrapper>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        margin: '0px 50px',
                    }}
                >
                    <Theme.AdminContainer>
                        <Theme.Icon src="/assets/icons/admins-icon.svg" alt="Admins" />
                        <Theme.DetailLabel>{trans('header.teamDetails.admins')}:</Theme.DetailLabel>
                    </Theme.AdminContainer>
                    <Theme.AdminContainer>
                        <Theme.AdminBadge>
                            <Theme.Icon src="/assets/icons/qustion-icon.svg" alt="Admins" />
                            <Theme.AdminName>Sport Profile Manager</Theme.AdminName>
                        </Theme.AdminBadge>
                        <span>~</span>
                        <Theme.AdminName>Mohamed Yasser</Theme.AdminName>
                    </Theme.AdminContainer>
                    <Theme.AdminContainer>
                        <Theme.AdminBadge>
                            <Theme.Icon src="/assets/icons/qustion-icon.svg" alt="Admins" />
                            <Theme.AdminName>Sport Profile Manager</Theme.AdminName>
                        </Theme.AdminBadge>
                        <span>~</span>
                        <Theme.AdminName>Mohamed Yasser</Theme.AdminName>
                    </Theme.AdminContainer>
                    <Theme.AdminContainer>
                        <Theme.AdminBadge>
                            <Theme.Icon src="/assets/icons/qustion-icon.svg" alt="Admins" />
                            <Theme.AdminName>Sport Profile Manager</Theme.AdminName>
                        </Theme.AdminBadge>
                        <span>~</span>
                        <Theme.AdminName>Mohamed Yasser</Theme.AdminName>
                    </Theme.AdminContainer>
                </div>
            </Theme.HeaderTeamWrapper>
        </Theme.Body>
    );
};
