import React from 'react';
import { useForm, FormProvider, Controller, SubmitHandler } from 'react-hook-form';
import { SharedModal } from '../../sharedModal';
import { useLocales } from 'hooks/locales';
import { useDispatch, useSelector } from 'react-redux';
import { selectControls, setModalContent } from 'store/controlsSlice';
import * as Theme from './Theme';
import { router } from 'routers';
import { LabelInput } from 'components/labelInput';
import { FormRow } from 'components/modal-windows/FormRow';
import { MultiSelectController } from 'components/multi-selection';
import { arrayToSelectOptions } from 'libs/helpers';
import { Divider } from 'components/modal-windows';
import { useGetAthletes } from 'hooks';
import { Relationship } from 'libs/enums';
import {
    useCreatePlayerContact,
    useUpdatePlayerContact,
} from 'services/hooks/players/useEditPlayerContact';

interface EditPlayerContactModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface EditPlayerContactForm {
    name: string;
    relation: any;
    phoneNumber: string;
    nationalNumber: string;
}

export const EditPlayerContact: React.FC<EditPlayerContactModalProps> = ({ isOpen, onClose }) => {
    const { trans } = useLocales();
    const dispatch = useDispatch();
    const { modalContent } = useSelector(selectControls);
    const {
        params: { id },
    } = router.getState();

    const { data: athlete } = useGetAthletes<any>({
        id: id || '',
        idType: 'athlete',
        dependents: [modalContent],
    });

    const methods = useForm<EditPlayerContactForm>({
        mode: 'all',
        defaultValues: {
            name: athlete?.emergencyContact?.name || '',
            relation: athlete?.emergencyContact?.relation || '',
            phoneNumber: athlete?.emergencyContact?.phoneNumber || '',
            nationalNumber: athlete?.emergencyContact?.nationalNumber || '',
        },
    });

    const { mutate: updatePlayerContact } = useUpdatePlayerContact(id, {
        onSuccess: (response) => {
            const isSuccess = [200, 201].includes(response.status);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'success',
                        title: trans('form.success'),
                        subtitle: isSuccess
                            ? trans('player.contactDataUpdatedSuccess')
                            : response.message || trans('form.error_occurred'),
                    },
                }),
            );
            if (isSuccess) {
                onClose();
            }
        },
        onError: (error) => {
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'warning',
                        title: trans('form.warning'),
                        subtitle: error.message || trans('form.error_occurred'),
                    },
                }),
            );
        },
    });

    const { mutate: createPlayerContact } = useCreatePlayerContact(id, {
        onSuccess: (response) => {
            const isSuccess = [200, 201].includes(response.status);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'success',
                        title: trans('form.success'),
                        subtitle: isSuccess
                            ? trans('player.contactDataCreatedSuccess')
                            : response.message || trans('form.error_occurred'),
                    },
                }),
            );
            if (isSuccess) {
                onClose();
            }
        },
        onError: (error) => {
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'warning',
                        title: trans('form.warning'),
                        subtitle: error.message || trans('form.error_occurred'),
                    },
                }),
            );
        },
    });

    const handleSave: SubmitHandler<EditPlayerContactForm> = (data) => {
        const contactData = {
            name: data.name,
            relation: data.relation.value,
            phoneNumber: data.phoneNumber,
            nationalNumber: data.nationalNumber,
        };

        // Decide whether to call create or update based on existing data
        const mutation = athlete?.emergencyContact ? updatePlayerContact : createPlayerContact;
        mutation(contactData);
    };

    const { control } = methods;

    return (
        <FormProvider {...methods}>
            <SharedModal
                isOpen={isOpen}
                onRequestClose={onClose}
                title={trans('player.Edit.contactData')}
            >
                <Theme.LineHR />
                <Theme.Body>
                    {/* Name Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.name')} />
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Theme.Input
                                        {...field}
                                        placeholder={trans('player.Edit.name')}
                                        required
                                    />
                                    {methods.formState.errors.name && (
                                        <Theme.ErrorText>
                                            {methods.formState.errors.name.message}
                                        </Theme.ErrorText>
                                    )}
                                </>
                            )}
                        />
                    </Theme.InputsWrapper>

                    {/* Relation Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.relation')}
                            content={
                                <MultiSelectController
                                    {...{
                                        control,
                                        name: 'relation',
                                        options: arrayToSelectOptions({ array: Relationship }),
                                        transSuffix: 'form.editAthleteProfile.',
                                        menuPlacement: 'bottom',
                                    }}
                                />
                            }
                        />
                        <Divider />
                    </Theme.InputsWrapper>

                    {/* Phone Number Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.phoneNumber')} />
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Theme.Input
                                        {...field}
                                        placeholder={trans('player.Edit.phoneNumber')}
                                        required
                                    />
                                    {methods.formState.errors.phoneNumber && (
                                        <Theme.ErrorText>
                                            {methods.formState.errors.phoneNumber.message}
                                        </Theme.ErrorText>
                                    )}
                                </>
                            )}
                        />
                    </Theme.InputsWrapper>

                    {/* National Number Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.nationalNumber')} />
                        <Controller
                            name="nationalNumber"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Theme.Input
                                        {...field}
                                        placeholder={trans('player.Edit.nationalNumber')}
                                        required
                                    />
                                    {methods.formState.errors.nationalNumber && (
                                        <Theme.ErrorText>
                                            {methods.formState.errors.nationalNumber.message}
                                        </Theme.ErrorText>
                                    )}
                                </>
                            )}
                        />
                    </Theme.InputsWrapper>

                    <Theme.LineHR />
                    <Theme.InputMultiElemintsWrapperRight>
                        <Theme.SubmitButton
                            type="button"
                            onClick={methods.handleSubmit(handleSave)}
                        >
                            <img
                                src="/assets/icons/add-icon-colored.svg"
                                height={20}
                                width={20}
                                alt="Save Icon"
                            />
                            {trans('player.Edit.contactData')}
                        </Theme.SubmitButton>
                    </Theme.InputMultiElemintsWrapperRight>
                </Theme.Body>
            </SharedModal>
        </FormProvider>
    );
};
