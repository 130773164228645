import { useLocales } from 'hooks/locales';
import { useState, useEffect } from 'react';
import { Loader } from 'components';
import { useSelector } from 'react-redux';
import { selectAcademy } from 'store';
import { useRouter } from 'react-router5';
import { TemplatesTableAdministrator } from 'components/templatesTableAdministrator';
import { useFetchAdministratorsTable } from 'services/hooks/administrator/useFetchAdministratorsTable';
interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}
export const Administrator = () => {
    const { trans } = useLocales();
    const [columns, setColumns] = useState<Column[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const router = useRouter();
    const {
        params: { sportId },
    } = router.getState();

    const { data, isLoading, isPending, error } = useFetchAdministratorsTable(sportId, page, limit);

    useEffect(() => {
        setColumns([
            {
                key: 'administrator',
                label: trans('administrator.table.administrator'),
                width: '20%',
                sortable: false,
            },
            {
                key: 'experience',
                label: trans('administrator.table.experience'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'type',
                label: trans('administrator.table.type'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'teamCategory',
                label: trans('administrator.table.teamCategory'),
                width: '15%',
                sortable: false,
            },
            {
                key: 'contract',
                label: trans('administrator.table.contract'),
                width: '15%',
                sortable: true,
            },
        ]);
    }, [trans]);
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div style={{ width: '100%' }}>
            {isLoading ? (
                <Loader />
            ) : (
                <TemplatesTableAdministrator columns={columns} data={data || []} />
            )}
        </div>
    );
};
