import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

export interface AddTeamsRequest {
    logo?: File; // Binary file for the team logo
    background?: File; // Binary file for the background image
    branch?: string; // Branch ID (UUID)
    name: string; // Team name
    coach: string; // Coach ID (UUID)
    admin: string; // Admin ID (UUID)
    subCoaches?: any; // List of sub-coach IDs (UUIDs)
    athletes: any; // List of athlete IDs (UUIDs)
    category?: string; // Team category (e.g., Beginner, Intermediate)
}

interface AddTeamsResponse {
    message: string; // Response message
    status: number; // HTTP status code
    payload: any; // API response payload
}

export const addTeams = async (
    sportClubProfileId: string,
    data: AddTeamsRequest,
): Promise<AddTeamsResponse> => {
    const formData = new FormData();

    if (data.logo) formData.append('logo', data.logo);
    if (data.background) formData.append('background', data.background);
    if (data.branch) formData.append('branch', data.branch);

    formData.append('name', data.name);
    formData.append('coach', data.coach);
    formData.append('admin', data.admin);
    formData.append('athletes', data.athletes);

    if (data.subCoaches) {
        formData.append('subCoaches', data.subCoaches);
    }
    if (data.category) {
        formData.append('category', data.category);
    }

    try {
        const response = await api
            .url(`sportClubProfile/${sportClubProfileId}/teams`)
            .post(formData)
            .json<AddTeamsResponse>();
        return response;
    } catch (error: any) {
        throw new Error(error?.message || 'Failed to create a team');
    }
};

export const useAddTeams = (
    sportClubProfileId: string,
    options?: UseMutationOptions<AddTeamsResponse, Error, AddTeamsRequest>,
): UseMutationResult<AddTeamsResponse, Error, AddTeamsRequest> => {
    return useMutation<AddTeamsResponse, Error, AddTeamsRequest>({
        mutationFn: (data: AddTeamsRequest) => addTeams(sportClubProfileId, data),
        ...options,
    });
};
