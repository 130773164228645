import { useDispatch, useSelector } from 'react-redux';
import { getAvatarPlaceholder } from 'libs/constants';
import { StoreState } from 'libs/types';
import { Gender, UserRole } from 'libs/enums';
import { router } from 'routers';
import { resetAction, switchActiveTab } from 'store/controlsSlice';
import { authSlice, selectAuth } from 'store/authSlice';
import { useLocales } from 'hooks/locales';
import * as Theme from './Theme';
import { useCallback, useState } from 'react';
import { useLayer } from 'react-laag';
import { WithRole } from 'hooks/roles';
import { resetLocalesAction } from 'store/localesSlice';
import { resetAction as resetActionAcademy } from 'store/academySlice';

export const ProfileMenu = () => {
    const { entities } = useSelector(selectAuth);
    const user = useSelector((state: StoreState) => state.auth?.entities);
    const dispatch = useDispatch<any>();
    const { trans } = useLocales();
    const [isOpen, setIsOpen] = useState(false);

    const { triggerProps, layerProps, renderLayer } = useLayer({
        isOpen,
        onOutsideClick: () => setIsOpen(false),
        onDisappear: () => setIsOpen(false),
        auto: true,
        placement: 'bottom-end',
        triggerOffset: 10,
    });

    const handleLogout = useCallback(() => {
        dispatch(authSlice.actions.clearAuthData());
        dispatch(resetLocalesAction());
        dispatch(resetAction());
        dispatch(resetActionAcademy());
        window.history.replaceState({}, '', 'home');
        router.navigate('signin', {}, { reload: true });
    }, [dispatch]);

    const handleAvatarClick = () => {
        if (entities?.userId) {
            dispatch(switchActiveTab({ activeTab: 'adminProfile' }));
            router.navigate('profile', { id: entities?.userId }, { replace: true });
        }
        setIsOpen(false);
    };

    return (
        <Theme.DropdownContainer>
            <button
                type="button"
                className="flex w-full justify-center items-center h-full"
                {...triggerProps}
                onClick={() => setIsOpen((prev) => !prev)}
            >
                <div className="flex w-full justify-center items-center">
                    <Theme.AvatarImage
                        alt="avatar"
                        src={user?.avatar || getAvatarPlaceholder(Gender.MALE)}
                    />
                    <div className="flex w-full items-end flex-col">
                        <div className="text-gray-900 text-[20px] font-semibold capitalize">
                            {user?.username || ''}
                        </div>
                        <div className="text-zinc-500 text-[12px] font-normal capitalize w-full text-start">
                            {trans(user?.role || '')}
                        </div>
                    </div>
                </div>
                <img
                    src="/assets/icons/arrow-down-icon.svg"
                    alt="dropdown icon"
                    width={16}
                    height={16}
                    className="ms-[16px]"
                />
            </button>

            {isOpen &&
                renderLayer(
                    <Theme.DropdownContent {...layerProps} isOpen={isOpen}>
                        <Theme.MenuItem
                            className="flex w-full items-center justify-start"
                            onClick={handleAvatarClick}
                        >
                            <img className="me-[10px]" src="/assets/icons/email-icon.svg" alt="" />
                            {user?.email}
                        </Theme.MenuItem>
                        <Theme.MenuItem onClick={handleAvatarClick}>
                            {trans('profile.editPersonalData')}
                        </Theme.MenuItem>
                        <Theme.MenuLine />
                        <WithRole allowRoles={[UserRole.CLUB_ADMIN]}>
                            <Theme.MenuItem
                                onClick={() => {
                                    router.navigate('clubProfile');
                                    setIsOpen(false);
                                }}
                            >
                                {trans('profile.clubProfile')}
                            </Theme.MenuItem>
                        </WithRole>
                        <Theme.MenuItem className="flex w-full flex-wrap" onClick={handleLogout}>
                            <div className="me-auto">{trans('home.logout')}</div>
                            <img src="/assets/icons/logout.svg" alt="" />
                        </Theme.MenuItem>
                    </Theme.DropdownContent>,
                )}
        </Theme.DropdownContainer>
    );
};
