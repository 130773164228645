import React, { useState } from 'react';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { YearGoalModal } from 'components/yearGoalModal';

export const GoalStatusBoard: React.FC<any> = ({ data }) => {
    const { trans } = useLocales();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    console.log(data.data);
    // Fake data for 4 months
    const yearData = [
        {
            month: 'January',
            goal: 'Get the maximum breath process (4/4)',
            weeks: [
                {
                    week: 'Week 1',
                    description: 'Spaces must achieved',
                    title: '2000 km',
                    dateRange: '[1 Jan .. 7 Jan]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
                {
                    week: 'Week 2',
                    description: 'Review and adjust processes',
                    title: '2000 km',
                    dateRange: '[8 Jan .. 14 Jan]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
                {
                    week: 'Week 3',
                    description: 'Finalize all pending goals',
                    title: '2000 km',
                    dateRange: '[15 Jan .. 21 Jan]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
                {
                    week: 'Week 4',
                    description: 'Conduct evaluations',
                    title: '2000 km',
                    dateRange: '[22 Jan .. 28 Jan]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
            ],
        },
        {
            month: 'February',
            goal: 'Focus on sustainable practices (4/4)',
            weeks: [
                {
                    week: 'Week 1',
                    description: 'Identify key areas for improvement',
                    title: '2000 km',
                    dateRange: '[1 Feb .. 7 Feb]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
                {
                    week: 'Week 2',
                    description: 'Implement new strategies',
                    title: '2000 km',
                    dateRange: '[8 Feb .. 14 Feb]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
                {
                    week: 'Week 3',
                    description: 'Evaluate effectiveness',
                    title: '2000 km',
                    dateRange: '[15 Feb .. 21 Feb]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
            ],
        },
        {
            month: 'March',
            goal: 'Achieve operational excellence (4/4)',
            weeks: [
                {
                    week: 'Week 1',
                    description: 'Streamline workflows',
                    title: '2000 km',
                    dateRange: '[1 Mar .. 7 Mar]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
                {
                    week: 'Week 2',
                    description: 'Reduce redundancies',
                    title: '2000 km',
                    dateRange: '[8 Mar .. 14 Mar]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
                {
                    week: 'Week 3',
                    description: 'Optimize resources',
                    title: '2000 km',
                    dateRange: '[15 Mar .. 21 Mar]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
                {
                    week: 'Week 4',
                    description: 'Finalize reports',
                    title: '2000 km',
                    dateRange: '[22 Mar .. 28 Mar]',
                    completedTasks: 6,
                    totalTasks: 6,
                },
            ],
        },
        {
            month: 'April',
            goal: 'Expand team capabilities (2/4)',
            weeks: [
                {
                    week: 'Week 1',
                    description: 'Identify training needs',
                    title: '2000 km',
                    dateRange: '[1 Apr .. 7 Apr]',
                    completedTasks: 5,
                    totalTasks: 6,
                },
                {
                    week: 'Week 2',
                    description: 'Plan training sessions',
                    title: '2000 km',
                    dateRange: '[8 Apr .. 14 Apr]',
                    completedTasks: 0,
                    totalTasks: 6,
                },
            ],
        },
    ];

    return (
        <Theme.Body>
            <YearGoalModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} />
            {yearData.map((monthData, monthIndex) => (
                <div key={monthIndex}>
                    <Theme.MonthTitle>
                        <li>
                            {monthData.month} ~ {monthData.goal}
                            <Theme.ButtonOpenModal
                                type="button"
                                onClick={() => setIsEditModalOpen(true)}
                            >
                                <img
                                    src="/assets/icons/edit.svg"
                                    height={16}
                                    width={16}
                                    alt="editGoal"
                                />
                            </Theme.ButtonOpenModal>
                        </li>
                    </Theme.MonthTitle>
                    {monthData.weeks.map((weekData, weekIndex) => {
                        const progressPercentage =
                            (weekData.completedTasks / weekData.totalTasks) * 100;
                        let progressColor;
                        let textColor;
                        if (weekData.completedTasks === 0) {
                            progressColor = '#eaecf0';
                            textColor = '#202403d9';
                        } else if (weekData.completedTasks < 6) {
                            progressColor = '#ffc00014';
                            textColor = '#ffc000';
                        } else {
                            progressColor = '#03985514';
                            textColor = '#039855';
                        }
                        return (
                            <>
                                <Theme.Weak>
                                    <Theme.WeekItem key={weekIndex}>
                                        <Theme.WeekTitle>{weekData.week}</Theme.WeekTitle>
                                        <Theme.DataItem
                                            style={{
                                                backgroundColor: progressColor,
                                                color: textColor,
                                            }}
                                        >
                                            <Theme.WeekDescription>
                                                {weekData.description}
                                            </Theme.WeekDescription>
                                            <Theme.WeekDetails>{trans('within')}</Theme.WeekDetails>
                                            <Theme.WeekDescription>
                                                {weekData.title}
                                            </Theme.WeekDescription>
                                        </Theme.DataItem>
                                    </Theme.WeekItem>
                                    <Theme.ProgressBar>
                                        <Theme.ProgressText>
                                            <span>
                                                {weekData.completedTasks}/{weekData.totalTasks}
                                                {trans('sessions')}
                                            </span>
                                            <span>{weekData.dateRange}</span>
                                        </Theme.ProgressText>
                                        <Theme.ProgressTrack>
                                            <Theme.ProgressFill
                                                style={{ width: `${progressPercentage}%` }}
                                            />
                                        </Theme.ProgressTrack>
                                    </Theme.ProgressBar>
                                </Theme.Weak>
                            </>
                        );
                    })}
                </div>
            ))}
        </Theme.Body>
    );
};
