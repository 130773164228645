import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

export interface AddSubGoalRequest {
    description: string;
    months: string;
    missionOne: string;
    unitNumber: number;
    unit: string;
    sessionsNeeded: number;
    missionTwo: string;
    unitNumberTwo: number;
    unitTwo: string;
    sessionsNeededTwo: number;
    missionThree: string;
    unitNumberThree: number;
    unitThree: string;
    sessionsNeededThree: number;
    missionFour: string;
    unitNumberFour: number;
    unitFour: string;
    sessionsNeededFour: number;
}

interface AddSubGoalResponse {
    message: string;
    status: number;
    payload: any;
}

export const addSubGoal = async (
    sportId: string,
    data: AddSubGoalRequest,
): Promise<AddSubGoalResponse> => {
    try {
        const response = await api
            .url(`sportClubProfile/${sportId}/subGoals`)
            .post(data)
            .json<AddSubGoalResponse>();
        return response;
    } catch (error: any) {
        throw new Error(error?.message || 'Failed to create a sub-goal');
    }
};

export const useAddSubGoal = (
    sportId: string,
    options?: UseMutationOptions<AddSubGoalResponse, Error, AddSubGoalRequest>,
): UseMutationResult<AddSubGoalResponse, Error, AddSubGoalRequest> => {
    return useMutation<AddSubGoalResponse, Error, AddSubGoalRequest>({
        mutationFn: (data: AddSubGoalRequest) => addSubGoal(sportId, data),
        ...options,
    });
};
