import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

interface ManagersTableRequest {
    clubId: string;
    page: number;
    limit: number;
}

interface ManagersTable {
    id: string;
    name: string;
    quantity: number;
    size: string[];
}

export const fetchManagersTable = async ({
    clubId,
    page,
    limit,
}: ManagersTableRequest): Promise<ManagersTable[]> => {
    const response = await api
        .url(`club/${clubId}/manager?page=${page}&limit=${limit}`)
        .get()
        .json<any>();

    return response.data;
};

export const useFetchManagersTable = (
    clubId: string,
    page: number = 1,
    limit: number = 50,
    options?: UseQueryOptions<ManagersTable[], Error>,
): UseQueryResult<ManagersTable[], Error> => {
    return useQuery<ManagersTable[], Error>({
        queryKey: ['fetchManagersTable', clubId, page, limit],
        queryFn: () => fetchManagersTable({ clubId, page, limit }),
        staleTime: 10000,
        ...options,
    });
};
