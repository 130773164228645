import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

export interface AddYearGoalRequest {
    startYearGoal: string;
    endYearGoal: string;
    description: string;
}

interface AddYearGoalResponse {
    message: string;
    status: number;
    payload: any;
}

export const addYearGoal = async (
    sportId: string,
    data: AddYearGoalRequest,
): Promise<AddYearGoalResponse> => {
    try {
        const response = await api
            .url(`sportClubProfile/${sportId}/YearGoals`)
            .post(data)
            .json<AddYearGoalResponse>();
        return response;
    } catch (error: any) {
        throw new Error(error?.message || 'Failed to create a Year-goal');
    }
};

export const useYearGoal = (
    sportId: string,
    options?: UseMutationOptions<AddYearGoalResponse, Error, AddYearGoalRequest>,
): UseMutationResult<AddYearGoalResponse, Error, AddYearGoalRequest> => {
    return useMutation<AddYearGoalResponse, Error, AddYearGoalRequest>({
        mutationFn: (data: AddYearGoalRequest) => addYearGoal(sportId, data),
        ...options,
    });
};
