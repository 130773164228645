export enum RouteName {
    HOME = 'home',
    ATHLETE = 'athlete',
    ADD_ATHLETE = 'addAthlete',
    TEAM = 'team',
    COACH = 'coach',
    ADD_TEAM = 'addTeam',
    ADD_COACH = 'addCoach',
    SIGN_IN = 'signin',
    SIGN_UP = 'signup',
    PROFILE = 'profile',
    ADD_SESSTION = 'add-sesstion-page',
    SESSION_TEMPLATES = 'add-sesstion-page/templates',
    TEMPLATE_SETTINGS = 'add-sesstion-page/templates/template-settings',
    CLOTHES = 'clothes',
    STOCK_CLOTHES = 'stock-clothes',
    MANAGER = 'manager',
    PLAYERS = 'Players',
    PLAYERS_DETAILS = 'players-details',
    TEAM_DETAILS = 'team-details',
    COACH_DETAILS = 'coach-details',
}

export enum ActiveTab {
    SCHEDULE = 'schedule',
    DASHBOARD = 'dashboard',
    COACH_LIST = 'coachList',
    TEAM_LIST = 'teamList',
    ATHLETE_LIST = 'athleteList',
    PLAYERS = 'Players',
    ACADEMY_PROFILE = 'academyProfile',
    CONTACTS = 'contacts',
    ADMIN_PROFILE = 'adminProfile',
    FEEDBACKS = 'feedbacks',
    APPROVAL_USERS = 'approval-users',
    PUBLIC_ATHLETE_LINKS = 'public-athlete-links',
    BRANCH = 'branch',
    ADMIN_MANAGER = 'admin_manager',
    BRANCH_LIST = 'branchList',
}
