import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';

export const ClubCard = ({ club }: any) => {
    const { trans } = useLocales();

    const {
        name,
        avatar,
        metrics,
        managers: { sportProfileManager, technicalDirector },
    } = club;

    return (
        <Theme.Card>
            <Theme.AvatarSection>
                <Theme.Avatar>
                    <img
                        src={avatar || '/default-avatar.png'}
                        alt={''}
                        style={{ width: '60%', height: '60%' }}
                    />
                </Theme.Avatar>
                <Theme.Title>{name}</Theme.Title>
            </Theme.AvatarSection>

            <Theme.DetailsSection>
                <Theme.Metrics>
                    <Theme.MetricItem>
                        <Theme.MetricValue>{club.metrics.teams}</Theme.MetricValue>
                        <Theme.MetricLabel>{trans('clubCard.metrics.teams')}</Theme.MetricLabel>
                    </Theme.MetricItem>
                    <Theme.MetricItem>
                        <Theme.MetricValue>{club.metrics.coaches}</Theme.MetricValue>
                        <Theme.MetricLabel>{trans('clubCard.metrics.coaches')}</Theme.MetricLabel>
                    </Theme.MetricItem>
                    <Theme.MetricItem>
                        <Theme.MetricValue>{club.metrics.administrators}</Theme.MetricValue>
                        <Theme.MetricLabel>
                            {trans('clubCard.metrics.administrators')}
                        </Theme.MetricLabel>
                    </Theme.MetricItem>
                    <Theme.MetricItem>
                        <Theme.MetricValue>{club.metrics.medicalTeam}</Theme.MetricValue>
                        <Theme.MetricLabel>
                            {trans('clubCard.metrics.medicalTeam')}
                        </Theme.MetricLabel>
                    </Theme.MetricItem>
                    <Theme.MetricItem>
                        <Theme.MetricValue>{club.metrics.supportTeam}</Theme.MetricValue>
                        <Theme.MetricLabel>
                            {trans('clubCard.metrics.supportTeam')}
                        </Theme.MetricLabel>
                    </Theme.MetricItem>
                </Theme.Metrics>
                <Theme.ManagersSection>
                    <Theme.ManagerItem>
                        {trans('clubCard.sportProfileManager')}:
                        <Theme.HighlightedText>{sportProfileManager}</Theme.HighlightedText>
                    </Theme.ManagerItem>
                    <Theme.ManagerItem>
                        {trans('clubCard.technicalDirector')}:
                        <Theme.HighlightedText>{technicalDirector}</Theme.HighlightedText>
                    </Theme.ManagerItem>
                </Theme.ManagersSection>
            </Theme.DetailsSection>

            {/* <Theme.ViewAllButton>{trans('clubCard.viewAll')}</Theme.ViewAllButton> */}
        </Theme.Card>
    );
};
