/* eslint-disable prettier/prettier */
import React from 'react';

export const ClubIcon = ({ color }: { color: string }) => (
    <svg
        width="112"
        height="116"
        viewBox="0 0 112 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M28.9855 74.8L3.50552 30.784C2.40681 28.8849 1.89009 26.7049 2.01951 24.5147C2.14893 22.3245 2.91879 20.2205 4.23352 18.464L13.2495 6.48C14.2928 5.089 15.6456 3.96001 17.2007 3.18242C18.7559 2.40483 20.4708 2 22.2095 2H89.4095C91.1483 2 92.8632 2.40483 94.4183 3.18242C95.9735 3.96001 97.3263 5.089 98.3696 6.48L107.33 18.464C108.653 20.2148 109.433 22.3159 109.572 24.5062C109.712 26.6965 109.204 28.8795 108.114 30.784L82.6336 74.8"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M50.2093 58.0001L17.2812 3.12012"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M61.4097 58.0001L94.3377 3.12012"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M33.4097 30.0001H78.2097"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M83.8096 86C83.8096 101.464 71.2736 114 55.8096 114C40.3456 114 27.8096 101.464 27.8096 86C27.8096 70.536 40.3456 58 55.8096 58C71.2736 58 83.8096 70.536 83.8096 86Z"
            fill={color}
            fillOpacity="0.08"
            stroke={color}
            strokeOpacity="0.85"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
