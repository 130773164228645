import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAcademy } from 'store';
import { router } from 'routers';
import { Vision } from 'components/teamsDetailsTabsAndComponants/Vision';
import { Gallery } from 'components/teamsDetailsTabsAndComponants/Gallery';
import { Players } from 'components/teamsDetailsTabsAndComponants/Players';
import { Sessions } from 'components/teamsDetailsTabsAndComponants/Sessions';
import { NextSesstion } from 'components/teamsDetailsTabsAndComponants/nextSesstion';
import { HeaderTeam } from 'components/teamsDetailsTabsAndComponants/headerTeamComponant';
import { useFetchTeamSession } from 'services/hooks/teamSession/useFetchTeamSession';

export const TeamDetails = () => {
    const { trans } = useLocales();
    const [activeTab, setActiveTab] = useState<string>('Vision');
    const { academy } = useSelector(selectAcademy);
    const {
        params: { sportId },
    } = router.getState();
    const {
        params: { id },
    } = router.getState();
    console.log(id);
    const { data: TeamSession, isLoading, error } = useFetchTeamSession(id);
    console.log('TeamSession', TeamSession);

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Vision':
                return <Vision data={TeamSession || []} />;
            // case 'Gallery':
            //     return <Gallery />;
            case 'Players':
                return <Players />;
            case 'Sessions':
                return <Sessions />;
            default:
                return <Vision />;
        }
    };

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    return (
        <div>
            <HeaderTeam />
            <NextSesstion />
            <Theme.TabsWrapper style={{ display: 'flex', cursor: 'pointer' }}>
                <Theme.Tabs
                    className={activeTab === 'Vision' ? 'active' : ''}
                    onClick={() => handleTabChange('Vision')}
                    role="tab"
                    aria-selected={activeTab === 'Vision'}
                >
                    {trans('tab.vision')}
                </Theme.Tabs>
                {/* <Theme.Tabs
                    className={activeTab === 'Gallery' ? 'active' : ''}
                    onClick={() => handleTabChange('Gallery')}
                    role="tab"
                    aria-selected={activeTab === 'Gallery'}
                >
                    {trans('tab.gallery')}
                </Theme.Tabs> */}
                <Theme.Tabs
                    className={activeTab === 'Players' ? 'active' : ''}
                    onClick={() => handleTabChange('Players')}
                    role="tab"
                    aria-selected={activeTab === 'Players'}
                >
                    {trans('tab.players')}
                </Theme.Tabs>
                <Theme.Tabs
                    className={activeTab === 'Sessions' ? 'active' : ''}
                    onClick={() => handleTabChange('Sessions')}
                    role="tab"
                    aria-selected={activeTab === 'Sessions'}
                >
                    {trans('tab.sessions')}
                </Theme.Tabs>
            </Theme.TabsWrapper>
            {renderTabContent()}
        </div>
    );
};
