import { UserRole } from 'libs/enums';
import * as pages from 'pages';
import { AuthLayout, DashboardLayout, PublicLayout } from 'layout';
import { RouteConfig, RouteNames } from './enum';

const pageRoutes: Record<RouteNames, RouteConfig> = {
    [RouteNames.ATHLETE_PUBLIC]: {
        Page: pages.AddAthletePublic,
        Layout: PublicLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.ADD_SESSTION]: {
        Page: pages.AddSessionPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN],
        isAuthPage: true,
    },
    [RouteNames.SESSION_TEMPLATES]: {
        Page: pages.SessionTemplates,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN],
        isAuthPage: true,
    },
    [RouteNames.TEMPLATE_SETTINGS]: {
        Page: pages.TemplateSettings,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN],
        isAuthPage: true,
    },
    [RouteNames.CLUB_CLOTHES]: {
        Page: pages.Clothes,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN],
        isAuthPage: true,
    },
    [RouteNames.STOCK_CLOTHES]: {
        Page: pages.StockClothes,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN],
        isAuthPage: true,
    },
    [RouteNames.MANAGER]: {
        Page: pages.Manager,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN],
        isAuthPage: true,
    },
    [RouteNames.ATHLETE_PUBLIC_WITH_CLUB]: {
        Page: pages.AddAthletePublic,
        Layout: PublicLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.HOME]: {
        Page: pages.Home,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ADMIN,
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.ACADEMY_COORDINATOR,
            UserRole.MEMBER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },

    [RouteNames.SCHEDULE]: {
        Page: pages.SchedulePage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.COACH],
        isAuthPage: false,
    },
    [RouteNames.SIGNIN]: {
        Page: pages.SignIn,
        Layout: AuthLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.FORGET_PASSWORD]: {
        Page: pages.ForgetPassword,
        Layout: AuthLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.REST_PASSWORD]: {
        Page: pages.ResetPassword,
        Layout: AuthLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.SIGNUP]: {
        Page: pages.SignUp,
        Layout: AuthLayout,
        allowedRoles: [],
        isAuthPage: true,
    },
    [RouteNames.ATHLETE]: {
        Page: pages.AthletePage,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.ADD_ATHLETE]: {
        Page: pages.AddAthlete,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.CLUB_ADMIN, UserRole.ADMIN_MANAGER],
        isAuthPage: false,
    },
    [RouteNames.PLAYERS]: {
        Page: pages.Players,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN, UserRole.ADMIN_MANAGER, UserRole.ACADEMY_ADMIN],
        isAuthPage: true,
    },
    [RouteNames.ADMINISTRATOR]: {
        Page: pages.Administrator,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN, UserRole.ADMIN_MANAGER, UserRole.ACADEMY_ADMIN],
        isAuthPage: true,
    },
    [RouteNames.PLAYERS_DETAILS]: {
        Page: pages.PlayersDetails,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN, UserRole.ADMIN_MANAGER, UserRole.ACADEMY_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.TEAM]: {
        Page: pages.TeamPage,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.TEAMS]: {
        Page: pages.Teams,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.TEAM_DETAILS]: {
        Page: pages.TeamDetails,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.ADD_TEAM]: {
        Page: pages.AddTeamPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.CLUB_ADMIN, UserRole.ADMIN_MANAGER],
        isAuthPage: false,
    },
    [RouteNames.ADD_COACH]: {
        Page: pages.AddCoachPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.CLUB_ADMIN, UserRole.ADMIN_MANAGER],
        isAuthPage: false,
    },
    [RouteNames.COACHES]: {
        Page: pages.Coaches,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.CLUB_ADMIN, UserRole.ADMIN_MANAGER],
        isAuthPage: false,
    },
    [RouteNames.ADD_BRANCH]: {
        Page: pages.AddBranchPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.CLUB_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.ADMIN_MANAGER]: {
        Page: pages.BranchPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.CLUB_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.BRANCH]: {
        Page: pages.BranchPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.CLUB_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.COACH]: {
        Page: pages.CoachPage,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_ADMIN, UserRole.ADMIN_MANAGER],
        isAuthPage: false,
    },
    [RouteNames.SESSION]: {
        Page: pages.SessionPage,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.TEAM_SESSION]: {
        Page: pages.TeamSessionPage,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.SESSION_UPDATE]: {
        Page: pages.SessionUpdatePage,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.INVITED_PLAYERS]: {
        Page: pages.SessionInvitedPlayer,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.PROFILE]: {
        Page: pages.AdminProfile,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ACADEMY_COORDINATOR,
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.FEEDBACKS]: {
        Page: pages.Feedbacks,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_COORDINATOR],
        isAuthPage: false,
    },
    [RouteNames.APPROVAL_USERS]: {
        Page: pages.ApprovalUsers,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.ACADEMY_COORDINATOR],
        isAuthPage: false,
    },
    [RouteNames.NOTIFICATION]: {
        Page: pages.Notification,
        Layout: DashboardLayout,
        allowedRoles: [
            UserRole.ADMIN,
            UserRole.ACADEMY_ADMIN,
            UserRole.CLUB_ADMIN,
            UserRole.ADMIN_MANAGER,
            UserRole.ACADEMY_COORDINATOR,
            UserRole.MEMBER,
            UserRole.COACH,
        ],
        isAuthPage: false,
    },
    [RouteNames.CONFIRM_SESSION]: {
        Page: pages.ConfirmSession,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.COACH],
        isAuthPage: false,
    },
    [RouteNames.CLUB_PROFILE]: {
        Page: pages.ClubProfile,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.CLUB_COACH]: {
        Page: pages.CoachTable,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.CLUB_TEAM]: {
        Page: pages.CoachTable,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.CLUB_ATHLETE]: {
        Page: pages.AthleteTable,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.COACH_DETAILS]: {
        Page: pages.CoachDetails,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN, UserRole.ADMIN_MANAGER, UserRole.ACADEMY_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.ADMINISTRATOR_DETAILS]: {
        Page: pages.AdministratorDetails,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN, UserRole.ADMIN_MANAGER, UserRole.ACADEMY_ADMIN],
        isAuthPage: false,
    },
    [RouteNames.SESSION_DETAILS]: {
        Page: pages.SessionDetails,
        Layout: DashboardLayout,
        allowedRoles: [UserRole.CLUB_ADMIN, UserRole.ADMIN_MANAGER, UserRole.ACADEMY_ADMIN],
        isAuthPage: false,
    },
};

export default pageRoutes;
