import React from 'react';
import { useForm, FormProvider, Controller, SubmitHandler } from 'react-hook-form';
import { SharedModal } from '../../sharedModal';
import { useLocales } from 'hooks/locales';
import { useDispatch, useSelector } from 'react-redux';
import { selectControls, setModalContent } from 'store/controlsSlice';
import * as Theme from './Theme';
import { router } from 'routers';
import { useEditPlayerPersonalInformation } from 'services/hooks/players/useEditPlayerPersonalInformation';
import { LabelInput } from 'components/labelInput';
import { FormRow } from 'components/modal-windows/FormRow';
import { MultiSelectController } from 'components/multi-selection';
import { addMonths, arrayToSelectOptions } from 'libs/helpers';
import { Divider } from 'components/modal-windows';
import { Education, Gender, Nationality, SkillLevel } from 'libs/enums';
import { useGetAthletes } from 'hooks';
import { InputDateController } from 'components/inputDate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdatePersonalInfoSchema } from 'schemas/player/useUpdatePersonalInfoSchema';

interface EditPlayerPersonalInformationModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface EditPlayerPersonalInformationForm {
    firstName: string;
    lastName: string;
    joinDate: Date;
    level: any;
    experience: string;
    education: any;
    schoolName: string;
    dateOfBirth: Date;
    gender: any;
    nationality: any;
    nin: string;
    ninExpirationDate: Date;
    weight: number;
    height: number;
}

export const EditPlayerPersonalInformation: React.FC<EditPlayerPersonalInformationModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { trans } = useLocales();
    const dispatch = useDispatch();
    const { modalContent } = useSelector(selectControls);

    const {
        params: { id },
    } = router.getState();
    console.log('id', id);

    const methods = useForm<EditPlayerPersonalInformationForm>({
        mode: 'all',
        defaultValues: {
            firstName: '',
            lastName: '',
            joinDate: new Date(),
            level: '',
            experience: '',
            education: '',
            schoolName: '',
            dateOfBirth: new Date(),
            gender: '',
            nationality: '',
            nin: '',
            ninExpirationDate: new Date(),
            weight: 0,
            height: 0,
        },
        resolver: yupResolver(useUpdatePersonalInfoSchema),
    });

    const { mutate } = useEditPlayerPersonalInformation(id, {
        onSuccess: (response) => {
            const isSuccess = [200, 201].includes(response.status);
            console.log(response);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'success',
                        title: trans('form.success'),
                        subtitle: isSuccess
                            ? trans('player.personalDataUpdatedSuccess')
                            : response.message || trans('form.error_occurred'),
                    },
                }),
            );
            onClose();
        },
        onError: (error) => {
            console.log('error', error);

            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'warning',
                        title: trans('form.warning'),
                        subtitle: error.message || trans('form.error_occurred'),
                    },
                }),
            );
            onClose();
        },
    });

    const handleSave: SubmitHandler<EditPlayerPersonalInformationForm> = (data) => {
        try {
            console.log('data', data);
            mutate({
                firstName: data.firstName,
                lastName: data.lastName,
                joinDate: data.joinDate,
                level: data.level.value,
                experience: data.experience,
                education: data.education.value,
                schoolName: data.schoolName,
                dateOfBirth: data.dateOfBirth,
                gender: data.gender.value,
                nationality: data.nationality.value,
                nin: data.nin,
                ninExpirationDate: data.ninExpirationDate,
                weight: data.weight,
                height: data.height,
            });
        } catch (error) {
            // Handle unexpected errors during the mutation
            console.error('Unexpected error:', error);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'warning',
                        title: trans('form.error'),
                        subtitle: trans('form.error_occurred'),
                    },
                }),
            );
            onClose();
        }
    };

    const { control } = methods;

    return (
        <FormProvider {...methods}>
            <SharedModal
                isOpen={isOpen}
                onRequestClose={onClose}
                customHeight="100%"
                title={trans('player.Edit.personalInformation')}
            >
                <Theme.LineHR />
                <Theme.Body>
                    {/* First Name Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.firstName')} />
                        <Controller
                            name="firstName"
                            control={methods.control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('player.Edit.firstName')}
                                />
                            )}
                        />
                        {methods.formState.errors.firstName && (
                            <span>{methods.formState.errors.firstName.message}</span>
                        )}
                    </Theme.InputsWrapper>

                    {/* Last Name Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.lastName')} />
                        <Controller
                            name="lastName"
                            control={methods.control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('player.Edit.lastName')}
                                />
                            )}
                        />
                        {methods.formState.errors.lastName && (
                            <span>{methods.formState.errors.lastName.message}</span>
                        )}
                    </Theme.InputsWrapper>

                    {/* Join Date Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.joinDate')}
                            content={
                                <InputDateController control={methods.control} name="joinDate" />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Level Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{
                                fontSize: '14px',
                                color: '#777777',
                                display: 'block',
                            }}
                            title={trans('label.level')}
                            content={
                                <MultiSelectController
                                    {...{
                                        control,
                                        name: 'level',
                                        options: arrayToSelectOptions({ array: SkillLevel }),
                                        transSuffix: 'form.add.player.',
                                        menuPlacement: 'bottom',
                                    }}
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Experience Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.experience')} />
                        <Controller
                            name="experience"
                            control={methods.control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('player.Edit.experience')}
                                />
                            )}
                        />
                        {methods.formState.errors.experience && (
                            <span>{methods.formState.errors.experience.message}</span>
                        )}
                    </Theme.InputsWrapper>

                    {/* Education Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.education')}
                            content={
                                <MultiSelectController
                                    control={control}
                                    name="education"
                                    options={arrayToSelectOptions({ array: Education })}
                                    transSuffix="form.editAthleteProfile."
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* School Name Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.schoolName')} />
                        <Controller
                            name="schoolName"
                            control={methods.control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    placeholder={trans('player.Edit.schoolName')}
                                />
                            )}
                        />
                        {methods.formState.errors.schoolName && (
                            <span>{methods.formState.errors.schoolName.message}</span>
                        )}
                    </Theme.InputsWrapper>

                    {/* Date of Birth Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.dateOfBirth')}
                            content={
                                <InputDateController control={methods.control} name="dateOfBirth" />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Gender Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.gender')}
                            content={
                                <MultiSelectController
                                    control={control}
                                    name="gender"
                                    options={arrayToSelectOptions({ array: Gender })}
                                    transSuffix="form.editAthletePersonalInfo."
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Nationality Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.nationality')}
                            content={
                                <MultiSelectController
                                    control={control}
                                    name="nationality"
                                    options={arrayToSelectOptions({ array: Nationality })}
                                    transSuffix="form.editAthleteProfile."
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* NIN Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.nin')} />
                        <Controller
                            name="nin"
                            control={methods.control}
                            render={({ field }) => (
                                <Theme.Input {...field} placeholder={trans('player.Edit.nin')} />
                            )}
                        />
                        {methods.formState.errors.nin && (
                            <span>{methods.formState.errors.nin.message}</span>
                        )}
                    </Theme.InputsWrapper>

                    {/* NIN Expiration Date Input */}
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.ninExpirationDate')}
                            content={
                                <InputDateController
                                    control={methods.control}
                                    name="ninExpirationDate"
                                    maxDate={addMonths(new Date(), 100)}
                                    minDate={new Date()}
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    {/* Weight Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.weight')} />
                        <Controller
                            name="weight"
                            control={methods.control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    type="number"
                                    placeholder={trans('player.Edit.weight')}
                                />
                            )}
                        />
                        {methods.formState.errors.weight && (
                            <span>{methods.formState.errors.weight.message}</span>
                        )}
                    </Theme.InputsWrapper>

                    {/* Height Input */}
                    <Theme.InputsWrapper>
                        <LabelInput label={trans('player.Edit.height')} />
                        <Controller
                            name="height"
                            control={methods.control}
                            render={({ field }) => (
                                <Theme.Input
                                    {...field}
                                    type="number"
                                    placeholder={trans('player.Edit.height')}
                                />
                            )}
                        />
                        {methods.formState.errors.height && (
                            <span>{methods.formState.errors.height.message}</span>
                        )}
                    </Theme.InputsWrapper>

                    <Theme.LineHR />
                    <Theme.InputMultiElemintsWrapperRight>
                        <Theme.SubmitButton
                            type="button"
                            onClick={methods.handleSubmit(handleSave)}
                        >
                            <img
                                src="/assets/icons/add-icon-colored.svg"
                                height={20}
                                width={20}
                                alt="Save Icon"
                            />
                            {trans('player.Edit.personalInformation')}
                        </Theme.SubmitButton>
                    </Theme.InputMultiElemintsWrapperRight>
                </Theme.Body>
            </SharedModal>
        </FormProvider>
    );
};
