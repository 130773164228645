import React, { useState } from 'react';
import { SharedModal } from '../sharedModal';
import { useLocales } from 'hooks/locales';
import { AccountInformation } from './tabsComponants/AccountInformation';
import { FileInformation } from './tabsComponants/FileInformation';
import { PersonalInformation } from './tabsComponants/PersonalInformation';
import * as Theme from './Theme';
import { useForm, FormProvider } from 'react-hook-form';
import { useAddManagers } from 'services/hooks/manager/useAddManagers';
import { useSelector, useDispatch } from 'react-redux';
import { selectAcademy } from 'store';
import { setModalContent } from 'store/controlsSlice';
import { useFetchManagersTable } from 'services/hooks/manager/useFetchManagersTable';
import { useManagerValidationSchema } from '../../schemas/manager/createManagerSchemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { array } from 'yup';

interface ManagerModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface ManagerFormValues {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    nationality: any;
    gender: any;
    birthday: Date;
    phone: string;
    emergencyPhone: string;
    relationship: any;
    educationalLevel: string;
    experience: number;
    nationalId: string;
    nationalIdExpirationDate: Date;
    position: any;
    joinDate: Date | null;
    contractRenewalTerms: string;
    avatar: any;
    contractDuration: number;
    contractType: any;
    contractStartDate: Date;
    contractEndDate: Date;
    contractBenefits: string;
}

export const ManagerModal: React.FC<ManagerModalProps> = ({ isOpen, onClose }) => {
    const { trans } = useLocales();
    const [resError, setResError] = useState('');
    const [activeTab, setActiveTab] = useState<'account' | 'file' | 'personal'>('account');
    const { academy } = useSelector(selectAcademy);
    const dispatch = useDispatch();
    const { mutateAsync, isError } = useAddManagers(academy.id);
    const { refetch } = useFetchManagersTable(academy.id);
    const validationSchema = useManagerValidationSchema();
    const methods = useForm<ManagerFormValues | any>({
        defaultValues: {
            firstName: '',
            lastName: '',
            nationality: undefined,
            gender: undefined,
            birthday: null,
            phone: '',
            emergencyPhone: '',
            relationship: undefined,
            educationalLevel: '',
            experience: null,
            nationalId: '',
            nationalIdExpirationDate: null,
            position: undefined,
            joinDate: null,
            contractRenewalTerms: '',
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            avatar: '',
            contractType: undefined,
            contractStartDate: null,
            contractEndDate: null,
            contractDuration: 0,
            contractBenefits: '',
        },
        mode: 'all',
        resolver: yupResolver(validationSchema as any),
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = methods;
    console.log('errors', errors);
    const handleTabChange = (tab: 'account' | 'file' | 'personal') => {
        setActiveTab(tab);
    };

    const handleSave = async (data: ManagerFormValues) => {
        try {
            const payload = {
                firstName: data.firstName,
                lastName: data.lastName,
                nationality: data.nationality.value,
                gender: data.gender.value,
                birthday: data.birthday,
                phone: data.phone,
                emergencyPhone: data.emergencyPhone,
                relationship: data.relationship,
                educationalLevel: data.educationalLevel,
                experience: data.experience,
                nationalId: data.nationalId,
                nationalIdExpirationDate: data.nationalIdExpirationDate,
                position: data.position.value,
                joinDate: data.joinDate,
                contractRenewalTerms: data.contractRenewalTerms,
                username: data.username,
                email: data.email,
                password: data.password,
                confirmPassword: data.confirmPassword,
                avatar: data.avatar,
                contractType: data.contractType.value,
                contractDuration: data.contractDuration,
                contractStartDate: data.contractStartDate,
                contractEndDate: data.contractEndDate,
                contractBenefits: data.contractBenefits,
            };
            const response = await mutateAsync(payload);

            if ([200, 201].includes(response.status)) {
                dispatch(
                    setModalContent({
                        modalContent: {
                            type: 'success',
                            title: trans('form.success'),
                            subtitle: trans('manager.created_successfully'),
                        },
                    }),
                );
                refetch();
                onClose();
                methods.reset();
            } else {
                dispatch(
                    setModalContent({
                        modalContent: {
                            type: 'warning',
                            title: trans('form.warning'),
                            subtitle: response.message || trans('form.error_occurred'),
                        },
                    }),
                );
            }
        } catch (error: any) {
            setResError(error.message);
            if (error.message === 'Error creating manager: Email already exists.') {
                setResError('email.already.exists');
            } else if (error.message === 'Error creating manager: Username already exists.') {
                setResError('username.already.exists');
            }
        }
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'account':
                return <AccountInformation />;
            case 'file':
                return <FileInformation />;
            case 'personal':
                return <PersonalInformation />;
            default:
                return null;
        }
    };

    return (
        <FormProvider {...methods}>
            <SharedModal
                isOpen={isOpen}
                onRequestClose={onClose}
                title={trans('modal.addnew.manager')}
                customHeight={activeTab === 'account' || activeTab === 'file' ? '100%' : undefined}
            >
                <Theme.LineHR />
                <Theme.TabsWrapper className="tabs-wrapper">
                    {['account', 'file', 'personal'].map((tab) => (
                        <Theme.TabButton
                            key={tab}
                            isActive={activeTab === tab}
                            onClick={() => handleTabChange(tab as 'account' | 'file' | 'personal')}
                            aria-selected={activeTab === tab}
                            aria-controls={`${tab}-tab`}
                        >
                            {trans(`tabs.${tab}`)}
                        </Theme.TabButton>
                    ))}
                </Theme.TabsWrapper>
                <Theme.Body>{renderTabContent()}</Theme.Body>
                <Theme.FooterButtonsWrapper>
                    {resError && (
                        <>
                            <Theme.Error>{trans(resError)}</Theme.Error>
                            <Theme.LineHR />
                        </>
                    )}
                    {activeTab !== 'account' && (
                        <Theme.NavButton type="button" onClick={() => handleTabChange('account')}>
                            <Theme.PreviousButton>
                                {/* <img
                                        src="/assets/icons/previous-icons.svg"
                                        height={16}
                                        width={16}
                                        alt="Previous Icon"
                                    /> */}
                                {trans('button.previous')}
                            </Theme.PreviousButton>
                        </Theme.NavButton>
                    )}
                    {activeTab !== 'personal' ? (
                        <Theme.SubmitButton
                            type="button"
                            onClick={() =>
                                handleTabChange(activeTab === 'account' ? 'file' : 'personal')
                            }
                        >
                            {trans('button.next')}
                            {/* <img
                                    src="/assets/icons/next-icons.svg"
                                    height={16}
                                    width={16}
                                    alt="Next Icon"
                                /> */}
                        </Theme.SubmitButton>
                    ) : (
                        <Theme.SubmitButton
                            type="button"
                            onClick={methods.handleSubmit(handleSave)}
                        >
                            <img
                                src="/assets/icons/next-icons.svg"
                                height={16}
                                width={16}
                                alt="Next Icon"
                            />
                            {trans('button.save')}
                        </Theme.SubmitButton>
                    )}
                </Theme.FooterButtonsWrapper>
            </SharedModal>
        </FormProvider>
    );
};
