import { stringToDateString } from 'libs/helpers';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import React from 'react';

interface HeaderSectionProps {
    adminDetails?: any;
}
const HeaderSection: React.FC<HeaderSectionProps> = ({ adminDetails }) => {
    const { trans } = useLocales();

    const fakeData = {
        subscription: {
            status: 'pending',
        },
        joinDate: '2025-01-20',
        type: 'Head',
    };

    return (
        <Theme.HeaderWrapper>
            <Theme.HeaderInfoWrapper>
                <Theme.BoxWrapper>
                    <Theme.SportSpan>Football</Theme.SportSpan>
                    <Theme.AcademySpan>El Hilal</Theme.AcademySpan>
                </Theme.BoxWrapper>
                <Theme.PlayerNameSpan>{adminDetails.name}</Theme.PlayerNameSpan>
                <Theme.SportUserDetails>
                    <Theme.UserInfoWrapper>
                        <Theme.DataLabel>{trans('coach.profile.details.id')}</Theme.DataLabel>
                        <Theme.DataContact># {adminDetails.id}</Theme.DataContact>
                    </Theme.UserInfoWrapper>
                    <Theme.UserInfoWrapper>
                        <Theme.DataLabel>{trans('coach.profile.details.age')}</Theme.DataLabel>
                        <Theme.DataContact>
                            {adminDetails.age.match(/\d+/g)?.[0]}{' '}
                            {trans('coach.profile.details.yearsOld')}
                        </Theme.DataContact>
                    </Theme.UserInfoWrapper>
                    <Theme.UserInfoWrapper>
                        <Theme.DataLabel>
                            {trans('coach.profile.details.experience')}
                        </Theme.DataLabel>
                        <Theme.DataContact>
                            {adminDetails.experience} {trans('coach.profile.details.years')}
                        </Theme.DataContact>
                    </Theme.UserInfoWrapper>
                    <Theme.UserInfoWrapper>
                        <Theme.DataLabel>
                            {trans('coach.profile.details.nationality')}
                        </Theme.DataLabel>
                        <Theme.DataContact>
                            {trans(`form.editAthleteProfile.${adminDetails.nationality}`)}
                        </Theme.DataContact>
                    </Theme.UserInfoWrapper>
                    <Theme.UserInfoWrapper>
                        <Theme.DataLabel>
                            {trans('coach.profile.details.languages')}
                        </Theme.DataLabel>
                        <Theme.DataContact>{adminDetails.languages}</Theme.DataContact>
                    </Theme.UserInfoWrapper>
                </Theme.SportUserDetails>
            </Theme.HeaderInfoWrapper>
            <Theme.HeaderInfoWrapper>
                <Theme.ContractInfoWrapper>
                    <Theme.ContractTitleSpan>
                        <ul>
                            {fakeData?.subscription?.status === 'active' && (
                                <li style={{ color: 'green' }}>
                                    {trans(
                                        `form.subscriptionManagement.status.${fakeData?.subscription?.status}`,
                                    )}
                                </li>
                            )}
                            {fakeData?.subscription?.status === 'inactive' && (
                                <li style={{ color: 'gray' }}>
                                    {trans(
                                        `form.subscriptionManagement.status.${fakeData?.subscription?.status}`,
                                    )}
                                </li>
                            )}
                            {fakeData?.subscription?.status === 'pending' && (
                                <li style={{ color: '#FFC000' }}>
                                    {trans(
                                        `form.subscriptionManagement.status.${fakeData?.subscription?.status}`,
                                    )}
                                </li>
                            )}
                            {fakeData?.subscription?.status === 'expired' && (
                                <li style={{ color: 'red' }}>
                                    {trans(
                                        `form.subscriptionManagement.status.${fakeData?.subscription?.status}`,
                                    )}
                                </li>
                            )}
                        </ul>
                    </Theme.ContractTitleSpan>
                    <Theme.ContractDateSpan>
                        {trans('till')} {stringToDateString(fakeData?.joinDate)}
                    </Theme.ContractDateSpan>
                    <Theme.UserInfoCard>
                        <Theme.ClubLogoWrapper>
                            <img src="/assets/images/club-logo.svg" alt="club logo" />
                        </Theme.ClubLogoWrapper>
                        <Theme.UserInfoStatus>
                            <Theme.DarkLabel>
                                {trans('coach.profile.details.male')}, 12-14{' '}
                                {trans('coach.profile.details.years')}
                            </Theme.DarkLabel>
                            <Theme.LeighLabel>2 Assistants</Theme.LeighLabel>
                        </Theme.UserInfoStatus>
                        <Theme.UserType>
                            <Theme.TypeLabel>
                                {trans(`form.addCoach.${adminDetails.profile.type}`)}
                            </Theme.TypeLabel>
                        </Theme.UserType>
                    </Theme.UserInfoCard>
                </Theme.ContractInfoWrapper>
            </Theme.HeaderInfoWrapper>
        </Theme.HeaderWrapper>
    );
};

export default HeaderSection;
