export enum FootballPosition {
    STRIKER = 'striker',
    RIGHT_WING = 'rightWing',
    LEFT_WING = 'leftWing',
    DEFENDER = 'defender',
    GOALKEEPER = 'goalkeeper',
}

export enum SwimmingDistance {
    SHORT_DISTANCE = 'shortDistance',
    MID_DISTANCE = 'midDistance',
    LONG_DISTANCE = 'longDistance',
}

export enum KarateBelt {
    WHITE_BELT = 'whiteBelt',
    YELLOW_BELT = 'yellowBelt',
    GREEN_BELT = 'greenBelt',
    ORANGE_BELT = 'orangeBelt',
    BLUE_BELT = 'blueBelt',
    BROWN_BELT = 'brownBelt',
    BLACK_BELT = 'blackBelt',
}
export enum AthleticsEvent {
    SPRINT_100M = 'sprint100m',
    SPRINT_200M = 'sprint200m',
    SPRINT_400M = 'sprint400m',
    MIDDLE_DISTANCE_800M = 'middleDistance800m',
    MIDDLE_DISTANCE_1500M = 'middleDistance1500m',
    LONG_DISTANCE_5000M = 'longDistance5000m',
    LONG_DISTANCE_10000M = 'longDistance10000m',
    HURDLES_100M = 'hurdles100m',
    HURDLES_110M = 'hurdles110m',
    HURDLES_400M = 'hurdles400m',
    RELAYS_4X100M = 'relays4x100m',
    RELAYS_4X400M = 'relays4x400m',
    STEEPLECHASE_3000M = 'steeplechase3000m',
    JUMPS_LONG = 'jumpsLong',
    JUMPS_TRIPLE = 'jumpsTriple',
    JUMPS_HIGH = 'jumpsHigh',
    JUMPS_POLE_VAULT = 'jumpsPoleVault',
    THROWS_SHOT_PUT = 'throwsShotPut',
    THROWS_DISCUS = 'throwsDiscus',
    THROWS_JAVELIN = 'throwsJavelin',
    THROWS_HAMMER = 'throwsHammer',
    HEPTATHLON_7 = 'heptathlon7',
    DECATHLON_10 = 'decathlon10',
}

export enum TaekwondoBelt {
    WHITE_BELT_STRIKER = 'whiteBeltStriker',
    WHITE_BELT_YELLOW_TAG_STRIKER = 'whiteBeltYellowTagStriker',
    WHITE_BELT_DOUBLE_YELLOW_TAG_STRIKER = 'whiteBeltDoubleYellowTagStriker',
    YELLOW_BELT_BLUE_TAG_STRIKER = 'yellowBeltBlueTagStriker',
    ORANGE_BELT_STRIKER = 'orangeBeltStriker',
    GREEN_BELT_STRIKER = 'greenBeltStriker',
    PURPLE_BELT_STRIKER = 'purpleBeltStriker',
    BLUE_BELT_STRIKER = 'blueBeltStriker',
    BLUE_BELT_RED_TAG_STRIKER = 'blueBeltRedTagStriker',
    BROWN_BELT_STRIKER = 'brownBeltStriker',
    RED_BELT_STRIKER = 'redBeltStriker',
    RED_BELT_BLACK_TAG_STRIKER = 'redBeltBlackTagStriker',
    BLACK_BELT = 'blackBelt',
}

export enum JudoBelt {
    WHITE_BELT = 'whiteBelt',
    YELLOW_BELT = 'yellowBelt',
    ORANGE_BELT = 'orangeBelt',
    GREEN_BELT = 'greenBelt',
    BLUE_BELT = 'blueBelt',
    BROWN_BELT = 'brownBelt',
    BLACK_BELT = 'blackBelt',
}

export enum JujutsuBelt {
    WHITE_BELT = 'whiteBelt',
    PURPLE_BELT = 'purpleBelt',
    CORAL_BELT = 'coralBelt',
    RED_BELT = 'redBelt',
    BLUE_BELT = 'blueBelt',
    BROWN_BELT = 'brownBelt',
    BLACK_BELT = 'blackBelt',
}

export enum FutsalPosition {
    STRIKER = 'striker',
    LEFT_WING = 'leftWing',
    RIGHT_WING = 'rightWing',
    DEFENDER = 'defender',
    GOALKEEPER = 'goalkeeper',
}

export enum BeachVolleyballEvent {
    DEFAULT_DOUBLE = 'defaultDouble',
}

export enum PadelEvent {
    DEFAULT_DOUBLE = 'defaultDouble',
}

export enum TriathlonEvent {
    DEFAULT_SINGLE = 'defaultSingle',
}

export enum BalootEvent {
    DEFAULT_DOUBLE = 'defaultDouble',
}

export enum WrestlingEvent {
    DEFAULT_SINGLE = 'defaultSingle',
}

export enum MuayThaiEvent {
    DEFAULT_SINGLE = 'defaultSingle',
}

export enum BoxingEvent {
    DEFAULT_SINGLE = 'defaultSingle',
}
