import { Loader } from 'components';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { useSelector } from 'react-redux';
import { useClubInfo } from 'services/hooks/clubProfile/useClubInfo';
import { selectAcademy } from 'store';
import { useState } from 'react';
import { EditClubModal } from '../editClubModal/EditClubModal';
import { router } from 'routers';

export const ClubInfo = () => {
    const { academy } = useSelector(selectAcademy);
    const { trans } = useLocales();

    const { data, error, isLoading } = useClubInfo(academy.id);

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <div>{trans('clubInfo.errorFetchingData')}</div>;
    }

    if (!data) {
        return <div>{trans('clubInfo.noDataAvailable')}</div>;
    }

    const { avatarUrl, createdAt, name, registrationNumber, contactNumber, address } = data.payload;
    const navigationToManagers = (academyId: string) => {
        router.navigate(`manager`, { academyId });
    };
    return (
        <Theme.Body>
            <Theme.LeftSection>
                <Theme.Avatar>
                    <img src={avatarUrl} alt="" style={{ width: '100%', height: '100%' }} />
                </Theme.Avatar>
                <Theme.CreatedDate>
                    {trans('clubInfo.created')}:{' '}
                    {new Date(createdAt || '').toLocaleDateString() ||
                        trans('clubInfo.noDateAvailable')}
                </Theme.CreatedDate>
            </Theme.LeftSection>

            <Theme.CenterSection>
                <Theme.Title className="capitalize">
                    {name || trans('clubInfo.noNameAvailable')}
                </Theme.Title>
                <Theme.Fields>
                    <Theme.Field>
                        <Theme.Label>{trans('clubInfo.commercialRegistration')}:</Theme.Label>
                        <Theme.Value>
                            {registrationNumber || trans('clubInfo.noRegistrationNumber')}
                        </Theme.Value>
                    </Theme.Field>
                    <Theme.Field>
                        <Theme.Label>{trans('clubInfo.phoneNumbers')}:</Theme.Label>
                        <Theme.Value>
                            {contactNumber || trans('clubInfo.noPhoneNumbers')}
                        </Theme.Value>
                    </Theme.Field>
                </Theme.Fields>
            </Theme.CenterSection>

            <Theme.RightSection>
                <EditClubModal initValue={data.payload} />
                <Theme.AddressField>
                    <Theme.Label>{trans('clubInfo.address')}:</Theme.Label>
                    <Theme.Value>{address || trans('clubInfo.noAddressAvailable')}</Theme.Value>
                </Theme.AddressField>
                <Theme.ManagerButton onClick={() => navigationToManagers(academy.id)}>
                    {trans('manager.redirct')}
                    <img src="/assets/icons/next-icon.svg" height={16} width={16} alt="Next Icon" />
                </Theme.ManagerButton>
            </Theme.RightSection>
        </Theme.Body>
    );
};
