import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { useFetchManagersTable } from '../../services/hooks/manager/useFetchManagersTable';
import { TemplatesTableTeams } from '../../components/templatesTableTeams';
import { useState, useEffect } from 'react';
import { Loader } from 'components';
import { useSelector } from 'react-redux';
import { selectAcademy } from 'store';
import { useFetchTeamsTable } from 'services/hooks/teams/useFetchTeamsList';
import { router } from 'routers';
interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}
export const Teams = () => {
    const { trans } = useLocales();
    const [columns, setColumns] = useState<Column[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const { academy } = useSelector(selectAcademy);
    const {
        params: { sportId },
    } = router.getState();
    const { data, isLoading, error } = useFetchTeamsTable(sportId, page, limit);
    useEffect(() => {
        setColumns([
            {
                key: 'category',
                label: trans('teams.table.category'),
                width: '20%',
                sortable: false,
            },
            {
                key: 'nickname',
                label: trans('teams.table.nickname'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'size',
                label: trans('teams.table.size'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'positions',
                label: trans('teams.table.positions'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'coach',
                label: trans('teams.table.coach'),
                width: '15%',
                sortable: false,
            },
            {
                key: 'sub-coach',
                label: trans('teams.table.sub-coach'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'next-session',
                label: trans('teams.table.next-session'),
                width: '15%',
                sortable: true,
            },
        ]);
    }, [trans]);

    if (error) return <div>Error: {error.message}</div>;
    return (
        <>{isLoading ? <Loader /> : <TemplatesTableTeams columns={columns} data={data || []} />}</>
    );
};
