import styled from 'styled-components';

export const Body = styled.div.attrs({
    className:
        'flex items-center justify-between bg-white border border-gray-300 rounded-lg shadow-md p-6 flex-wrap md:flex-nowrap',
})``;

export const LeftSection = styled.div.attrs({
    className: 'flex flex-col items-center justify-center md:w-[20%] w-full mb-4 md:mb-0',
})``;

export const Avatar = styled.div.attrs({
    className: 'w-24 h-24 md:w-32 md:h-32 rounded-[15px] overflow-hidden mb-2',
})`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    border: 1px #dbddd0 solid;

    &:empty {
        background: #f0f0f0;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`;

export const CreatedDate = styled.div.attrs({
    className: 'text-sm text-gray-500',
})``;

export const CenterSection = styled.div.attrs({
    className: 'md:w-[50%] w-full text-start',
})``;

export const Title = styled.h1.attrs({
    className: 'text-xl font-bold text-gray-900 mb-4',
})``;

export const Fields = styled.div.attrs({
    className: 'space-y-4',
})``;

export const Field = styled.div.attrs({
    className: 'flex flex-col',
})``;

export const Label = styled.span.attrs({
    className: 'text-sm text-gray-500 mb-1',
})``;

export const Value = styled.span.attrs({
    className: 'text-sm font-medium text-gray-900',
})``;

export const RightSection = styled.div.attrs({
    className: 'flex flex-col items-end md:w-[30%] w-full text-end space-y-4',
})``;

export const AddressField = styled.div.attrs({
    className: 'text-start',
})``;

export const EditButton = styled.button.attrs({
    className: 'bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all',
})``;
export const ManagerButton = styled.button`
    justify-content: space-around;
    align-items: center;
    border: 1px solid;
    padding: 8px 12px;
    border-radius: 10px;
    width: 100%;
    max-width: 150px;
    display: flex;
`;
